// Plan Features

.section.plan {

    //font-size: 15px;

    .plan-description {
        font-size: 15px;
    }

    ul.plan-items {
        font-size: 15px;
        margin-top: 10px;
        line-height: 1.5;
        list-style: none;
        text-align: left;
        padding-right: 5px;
        padding-left: 25px;
    }

    li {

        &.not-included{
            color: $gray4;
        }

        .fa-check {
            color: $green;
            margin-right: 4px;
        }

        .fa-times {
            color: $red;
            margin-right: 4px;
            margin-left: 1px;
        }

        .fa-question-circle {
            color: $gray5;
            margin-left: 5px;

            &:hover{
                color: $gray4;
            }
        }
    }

}

.subscribe-mp.prompt-content {
    margin: 100px 70px 0;
}