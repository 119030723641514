@font-face {
  font-family: 'perfit-icons';
  src: url('../fonts/perfit-icons-v2.eot?97549422');
  src: url('../fonts/perfit-icons-v2.eot?97549422#iefix') format('embedded-opentype'),
       url('../fonts/perfit-icons-v2.woff2?97549422') format('woff2'),
       url('../fonts/perfit-icons-v2.woff?97549422') format('woff'),
       url('../fonts/perfit-icons-v2.ttf?97549422') format('truetype'),
       url('../fonts/perfit-icons-v2.svg?97549422#filtros') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'filtros';
    src: url('../font/filtros.svg?97549422#filtros') format('svg');
  }
}
*/

 [class^="pi-"]:before, [class*=" pi-"]:before {
  font-family: "perfit-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}



.pi-paging-next:before { content: '\e800'; }
.pi-paging-options:before { content: '\e801'; }
.pi-paging-prev:before { content: '\e802'; }
.pi-preview-desktop:before { content: '\e803'; }
.pi-preview-mobile:before { content: '\e804'; }
.pi-warning:before { content: '\e805'; }
.pi-warning-ok:before { content: '\e806'; }
.pi-warning-wrong:before { content: '\e807'; }
.pi-back:before { content: '\e808'; }
.pi-button-add:before { content: '\e809'; }
.pi-button-change:before { content: '\e80a'; }
.pi-button-delete:before { content: '\e80b'; }
.pi-button-edit:before { content: '\e80c'; }
.pi-button-goto:before { content: '\e80d'; }
.pi-button-next:before { content: '\e80e'; }
.pi-button-prev:before { content: '\e80f'; }
.pi-button-save:before { content: '\e810'; }
.pi-button-share:before { content: '\e811'; }
.pi-campaign-bounce:before { content: '\e812'; }
.pi-campaign-click:before { content: '\e813'; }
.pi-campaign-description:before { content: '\e814'; }
.pi-campaign-open:before { content: '\e815'; }
.pi-campaign-social:before { content: '\e816'; }
.pi-campaign-spam:before { content: '\e817'; }
.pi-campaign-tag:before { content: '\e818'; }
.pi-campaign-time:before { content: '\e819'; }
.pi-campaign-unsubscribed:before { content: '\e81a'; }
.pi-close:before { content: '\e81b'; }
.pi-drop-down:before { content: '\e81c'; }
.pi-drop-up:before { content: '\e81d'; }

.pi-filter-close:before { content: '\e81e'; }
.pi-filter-interests:before { content: '\e81f'; }
.pi-filter-lists:before { content: '\e820'; }
.pi-filter-search:before { content: '\e821'; }
.pi-filter-state:before { content: '\e822'; }
.pi-filter-tag:before { content: '\e823'; }

.pi-folder:before { content: '\e824'; }
.pi-help:before { content: '\e825'; }
.pi-home:before { content: '\e826'; }
.pi-input-account:before { content: '\e827'; }
.pi-input-checkbox-off:before { content: '\e828'; }
.pi-input-checkbox-on:before { content: '\e829'; }
.pi-input-checkbox-partial:before { content: '\e82a'; }
.pi-input-invisible:before { content: '\e82b'; }
.pi-input-mail:before { content: '\e82c'; }
.pi-input-password:before { content: '\e82d'; }
.pi-input-radio-check:before { content: '\e82e'; }
.pi-input-radio-off:before { content: '\e82f'; }
.pi-input-radio-on:before { content: '\e830'; }
.pi-input-user:before { content: '\e831'; }
.pi-input-visible:before { content: '\e832'; }
.pi-item-account:before { content: '\e833'; }
.pi-item-contact:before { content: '\e834'; }
.pi-item-contactf:before { content: '\e835'; }
.pi-item-contactm:before { content: '\e836'; }
.pi-item-field:before { content: '\e837'; }
.pi-item-field-custom:before { content: '\e838'; }
.pi-item-interest:before { content: '\e839'; }
.pi-item-list:before { content: '\e83a'; }
.pi-item-optin:before { content: '\e83b'; }
.pi-item-selector-checked:before { content: '\e83c'; }
.pi-item-selector-partial:before { content: '\e83d'; }
.pi-item-selector-unchecked:before { content: '\e83e'; }
.pi-menu-logout:before { content: '\e83f'; }
.pi-menu-notification:before { content: '\e840'; }
.pi-menu-notification-done:before { content: '\e841'; }
.pi-menu-notification-info:before { content: '\e842'; }
.pi-menu-notification-payment:before { content: '\e843'; }
.pi-menu-notification-warning:before { content: '\e844'; }
.pi-menu-profile:before { content: '\e845'; }
.pi-menu-switch:before { content: '\e846'; }
.pi-menu-task:before { content: '\e847'; }
.pi-menu-task-export:before { content: '\e848'; }
.pi-menu-task-import:before { content: '\e849'; }
.pi-menu-task-list:before { content: '\e84a'; }
.pi-menu-task-send:before { content: '\e84b'; }
.pi-paging-first:before { content: '\e84c'; }
.pi-paging-last:before { content: '\e84d'; }



.task-loader,
.task-loader:after {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.task-loader {
  margin: 4px;
  position: relative;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spintask 1.2s infinite linear;
  animation: spintask 1.2s infinite linear;
  display: block;
}

.open .task-loader {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid rgba(0, 0, 0, 0.8);
}

@-webkit-keyframes spintask {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spintask {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
