/*

General

*******************************************/

/* Animations */

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Page */
@import url('https://rsms.me/inter/inter.css');
html, input:-webkit-autofill {
  font-family: 'Inter', sans-serif;
  font-feature-settings: "cv01","cv02","cv03","cv04","cv09";
}
@supports (font-variation-settings: normal) {
  html, input:-webkit-autofill {
    font-family: 'Inter var', sans-serif;
  }
}

html,
body {
  /* font-family: 'Source Sans Pro', sans-serif; */
  background-color: transparent;
  font-size: 16px;
  overscroll-behavior: none;
  /* user-select: none; */
  height: auto;
}

label {
  font-size: 0.875rem;
  margin-bottom: 4px;
}

p {
  margin: 0;
}

b, strong {
  font-weight: 600;
}

code {
  color: inherit;
  background-color: inherit;
}

.form-control::-moz-placeholder {
  color: #a3a3a3;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #a3a3a3
}

.form-control::-webkit-input-placeholder {
  color: #a3a3a3
}

[data-link] {
  cursor: pointer;
}

a {
  color: #00aee8;
}

a:hover,
a:focus,
a:focus:active,
a:active {
  color: #0E84B6;
}

a,
a:hover,
a:focus,
a:active,
a:active:focus,
a:visited,
.btn-link:hover {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  opacity: 1;
  background-color: #ddd;
}

.btn[disabled]:hover {
  background-color: #ddd;
}

.bold {
  font-weight: bold;
}

.text-muted {
  color: #b7b7b7;
}

.checkbox+.checkbox,
.radio+.radio {
  margin-top: 0;
}

.checkbox, .radio {
  font-size: 0.875rem;
}

.list ul.table > div > div.info-cell > strong {
  letter-spacing: inherit;
}
/*
a > i.fa {
    vertical-align: middle;
}
*/

/* Buttons */

.btn,
.btn:hover,
.btn:active,
.btn:active:focus,
.btn:focus {
  outline: none;
  /* text-transform: uppercase; */
  border-width: 1px;
  border-color: transparent;
  /* padding: 10px 16px; */
  border-radius: 5px;
  /* font-size: 12px; */
  /* font-weight: bold; */
  /* font-family: 'Open Sans', Helvetica, Arial, sans-serif; */
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  padding: 0.5rem 1rem;
  /* margin-left: 3px;
  margin-right: 3px; */
}

.btn-primary {
  background-color: #00aee8;
  border-color: #00aee8;
  color: #FFF;
}

.btn-primary:hover {
  background-color: #0b83b7;
}

.btn-primary.btn-outline {
  background-color: #FFF;
  color: #00AEE8;
  /* padding: 8px 16px; */
  border: 1px solid #00AEE8;
}

.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:active,
.btn-primary.btn-outline:active:focus {
  background-color: #FFF;
  color: #0b83b7;
  /* padding: 8px 16px; */
  border: 1px solid #0b83b7;
}

.btn.disabled,
.btn[disabled],
.btn-primary.btn-outline[disabled],
.btn-primary.btn-outline[disabled]:hover  {
  border-color: #ccc !important;
  color: #ccc;
}

.btn:focus {
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px, rgb(84, 201, 240) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  /* box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000); */
}


.btn-link {
  color: #0b83b7;
  white-space: normal;
}

.import-toolbar {
  overflow: hidden;
}

/* Loading Button */

button.btn .loader {
  height: 16px;
  margin-top: -3px;
  margin-right: 6px;
  display: inline;
}

.input-group .input-group-btn .btn {
  padding: 7px 26px 9px;
  /* font-size: initial; */
}

/* Forms */

.form-control {
  font-size: 0.875rem;
  height: auto;
  padding: 0.5rem 1rem;
  transition: none;
}

select.form-control {
  appearance: auto;
}

.has-feedback .form-control-feedback {
  line-height: 34px;
}

.has-error .help-block,
.signup-error,
.error-message {
  color: #ff5722;
  font-size: 0.9em;
}

.error-hidden {
  display: none;
}

.form-group {
  margin-bottom: 30px;
}

.form-group>label,
.form-group label {
  color: #00aee8;
  font-weight: normal;
}

.form-group .input-legend {
  margin: 8px 0 10px 0;
  color: #737373;
  font-size: 0.875rem;
}

.form-group .form-control {
  box-shadow: none;
  border-radius: 6px;
}

.form-group .form-control:focus {
  /* border-color: #00aee8 !important; */
  /* box-shadow: none !important; */
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgb(0, 173, 232) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-color: rgb(0, 173, 232);
}

.has-error .form-control:focus {
  border-color: #de4d1f !important;
}

.has-error .input-group-addon,
.has-error textarea.form-control,
.has-error select.form-control,
.has-error input.form-control {
  background-color: #fff8f5;
  border-color: #ff5722;
  color: #ff5722;
}

.input-color {
  cursor: pointer;
  max-width: 3rem;
  height: 3rem;
  padding: .375rem!important;
  border: 1px solid #ced4da;
}

.form-group .monospace {
  font-family: monospace;
  font-size: 13px;
}

/* Labels */

.label-outline {
  font-size: 12px;
  background: #e5e5e5;
  color: #696969;
  border: none;
}

.label-default {
  font-size: 12px;
  background: #696969;
  color: #FFF;
  border: 1px solid #696969;
}

.list-tags {
  margin-left: 10px;
  vertical-align: text-bottom;
}

/* Panels */

.relative {
  position: relative;
}

.panel {
  border-radius: 0.5rem;
  /* overflow: hidden; */
  /* box-shadow: 0 1px 2px rgba(15, 15, 21, .05), 0 2px 10px rgba(15, 15, 21, .025), 0 2px 4px rgba(0, 11, 20, .02); */
  /*min-width: 300px;*/
  margin-left: auto;
  margin-right: auto;
  border-color: rgb(229,229,229);
  box-shadow: none;
  position: relative;
}

.panel .panel-head {
  font-size: 1rem;
  padding-top: 19px;
  padding-bottom: 8px;
  margin: 0;
  font-weight: 500;
  margin-left: 10px;
}

.panel .panel-footer {
  /* position: absolute;
  bottom: 0; */
}

.panel .panel-head.with-icon {
  background-position: 0 10px;
  padding-left: 38px;
}

.panel .panel-head.tooltip-icon {
  margin-right: 0.5rem;
}

.panel .panel-body {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  font-size: 0.875rem;
}

.panel .panel-body.panel-body-unpadded {
  padding: 0;
}

.panel .panel-body.panel-body-padded {
  padding: 45px 80px;
}

/*

Graphic Radios

*******************************************/

.radio.graphic label {
  /*background-image: url(../img/icons/list-check-radio.png);
    background-repeat: no-repeat;*/
  min-height: 21px;
  margin-right: 18px;
  padding-left: 2px;
  font-size: 0.875rem;
}

.radio.graphic input[type=radio] {
  cursor: pointer;
  display: none;
}

.radio.graphic input[type=radio]+label:before {
  /*background-image: url(../img/icons/list-checked-radio.png);*/
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  width: 23px;
  font-size: 1.6em;
  color: #00aee8;
  vertical-align: sub;
}

.radio.graphic input[type=radio]:disabled+label:before {
  color: #ccc;
  cursor: default;
}

.radio.graphic input[type=radio]:disabled+label {
  color: #ccc;
  cursor: default;
}

.radio.graphic input[type=radio]:not(:checked)+label:before {
  content: "\f1db";
  /* Radio Unchecked */
}

.radio.graphic input[type=radio]:checked+label:before {
  content: "\f192";
  /* Radio Checked */
}

.radio.graphic input[type=radio]:not(:disabled)+label:hover:before {
  color: #0b83b7 !important;
}

/*

Graphic Checkboxes

*******************************************/

.checkbox.graphic label {
  /*    background-image: url(../img/icons/list-check.png);
    background-repeat: no-repeat;*/
  min-height: 21px;
  margin-right: 18px;
  padding-left: 2px;
  font-size: 0.875rem;
}

.checkbox.graphic input[type=checkbox] {
  cursor: pointer;
  display: none;
}

.checkbox.graphic input[type=checkbox]+label:before {
  /*background-image: url(../img/icons/list-checked.png);*/
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  width: 23px;
  font-size: 1.6em;
  color: #00aee8;
  vertical-align: sub;
}

.checkbox.graphic input[type=checkbox]:disabled+label:before {
  color: #ccc;
  cursor: default;
}

.checkbox.graphic input[type=checkbox]:disabled+label {
  color: #ccc;
  cursor: default;
}

.checkbox.graphic input[type=checkbox]:not(:checked)+label:before {
  content: "\f096";
  /* Checkbox Unchecked */
}

.checkbox.graphic input[type=checkbox]:checked+label:before {
  content: "\f14a";
  /* Checkbox Checked */
}

.checkbox.graphic input[type=checkbox]:not(:disabled)+label:hover:before {
  color: #0b83b7 !important;
}

.disabled-checkbox {
  color: #ccc!important; /* Tono gris para el texto del label */
  cursor: not-allowed!important; /* Cambia el cursor para indicar que está deshabilitado */
}

.disabled-checkbox::before {
  content: "\f14a"; /* Código de FontAwesome para un checkbox marcado */
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  width: 23px;
  font-size: 1.6em;
  color: #ccc; /* Color gris para simular un estado deshabilitado */
  vertical-align: sub;
}

.checkbox.graphic.inline {
  display: inline;
}

/*

Breadcrumb

*******************************************/

.breadcrumb {
  border-radius: 0;
  display: inline-block;
  background: transparent;
  padding: 9px;
  font-size: 0.875rem;
}

/*

Tabs

*******************************************/
.content {
  color: #696969;
}

.content .nav-tabs,
.content .nav-pills,
.modal .nav-tabs,
.modal .nav-pills {
  margin-bottom: 20px;
}

/* Tabs */

.content .nav-pills {
  border-bottom: 1px solid #00aee8;
}

.content .nav-pills li a {
  border-radius: 0;
  padding: 10px 0;
  color: #00aee8;
  background: none;
  text-align: left;
  border-width: 1px;
  background: none;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 40px;
  border: none;
}

.content .nav-pills li:last-child a {
  margin-right: 0;
}

.content .nav-pills li.active a,
.content .nav-pills li.active a:hover,
.content .nav-pills li.active a:focus,
.content .nav-pills li.active a:active {
  color: #0b83b7;
  background: none;
  border-bottom: 3px solid #00aee8;
}

.content .nav-pills li a:hover {
  color: #0b83b7;
}

.content .nav-pills li a .pill-icon {
  border-radius: 16px;
  font-size: 12px;
  line-height: 1;
  margin-right: 10px;
  padding: 2px 0px 0px 0px;
  background: #FFF;
  color: #00aee8;
  border: 2px solid #00aee8;
  font-weight: bold;
  display: inline-block;
  height: 20px;
  width: 20px;
  text-align: center;
}

.content .nav-pills li.active {
  margin-bottom: 0px;
}

.content .nav-pills li.active a .pill-icon {
  background: #00aee8;
  color: #FFF;
  border: 2px solid transparent;
}

.content .nav>li.disabled>a,
.content .nav>li.disabled>a:hover {
  color: #00aee8;
}

/*

Dropdowns

*******************************************/

.dropdown-menu {
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  border-top: 2px solid #696969;
  border-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/*

Modals

*******************************************/

.modal-dialog .modal-content {
  border-radius: 8px;
}

.modal-dialog .modal-content .modal-header {
  padding: 40px 50px 0;
  border: 0;
}

.modal-dialog .modal-content .modal-header>.close {
  margin-top: -8px;
  font-size: 32px;
  font-weight: normal;
  outline: none;
}

.modal-dialog .modal-content .modal-header>.modal-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #525252;
  margin-bottom: 1.5rem;
}

.modal-dialog .modal-content .modal-body {
  padding: 0;
  color: #696969;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.modal-dialog .modal-content .modal-body.scrollbar {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog .modal-content .modal-body .modal-body-padded {
  padding: 0 50px;
}

.modal-dialog .modal-content .modal-body>.description {
  font-size: 14px;
  font-weight: bold;
  color: #696969;
  margin: 0;
}

.modal-dialog .modal-content .modal-body>.time {
  font-size: 14px;
  color: #b7b7b7;
}

.modal-dialog .modal-content .modal-body ul.default-list {
  margin: 0 0 20px;
}

.modal-dialog .modal-content .modal-body ul.details {
  margin: 0;
  padding: 0;
  list-style: none;
}

.modal-dialog .modal-content .modal-body ul.details>li {
  color: #696969;
  font-size: 14px;
  display: block;
  background: #FFF;
}

.modal-dialog .modal-content .modal-body ul.details>li:last-child {
  border: none;
}

.modal-dialog .modal-content .modal-body ul.details>li>a {
  float: right;
  color: #00AEE8;
}

.modal-dialog .modal-content .modal-body ul.details>li>a:hover {
  color: #00AEE8;
}

.modal-dialog .modal-content .modal-body ul.details>li>.check-cell {
  display: inline-block;
  float: left;
  margin-top: 0;
}

.modal-dialog .modal-content .modal-body ul.details>li>.check-cell .handler {
  display: inline-block;
  height: 21px;

  background: #fff url("../img/icons/options.png") no-repeat left center;
  padding-left: 18px !important;
}

.modal-dialog .modal-content .modal-body ul.details>li>.check-cell>input {
  display: none;
}

.modal-dialog .modal-content .modal-body ul.details>li>.check-cell>.iconic {
  background-image: url(../img/icons/lists/item-selector-checked.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 21px;
  width: 21px;
  margin: 0;
  display: inline-block;
  margin-right: 18px;
}

.modal-dialog .modal-content .modal-body ul.details>li>.check-cell>input:checked+.iconic {
  background-image: url(../img/icons/list-checked.png);
}

.modal-dialog .modal-content .modal-body ul.details>li>.check-cell.radio>.iconic {
  background-image: url(../img/icons/list-check-radio.png);
}

.modal-dialog .modal-content .modal-body ul.details>li>.check-cell.radio>input:checked+.iconic {
  background-image: url(../img/icons/list-checked-radio.png);
}

.modal-dialog .modal-content .modal-body ul.details>li>.main-cell {
  display: block;
}

.modal-dialog .modal-content .modal-body ul.details>li .graphic {
  margin: 0;
}

.modal-dialog .modal-content .modal-body ul.details>li .graphic label {
  display: block;
  padding: 0.375rem 0;
}

.modal-dialog .modal-content .modal-body ul.details>li .graphic label .text-muted {
  margin: 0;
  padding-left: 25px;
}

.modal-dialog .modal-content .modal-body ul.grid {
  margin: 0 -40px 0 0;
}

.modal-dialog .modal-content .modal-body p {
  margin-bottom: 20px;
}

.modal-dialog .modal-content .modal-body a {
  /* font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; */
}

.modal-dialog .modal-content .modal-mini-footer {
  overflow: auto;
  text-align: right;
  margin-bottom: 35px;
}

.modal-dialog .modal-content .modal-footer {
  padding-right: 50px;
  padding-left: 45px;
  border: none;
  padding-bottom: 35px;
}

.modal-dialog .modal-content .modal-footer.modal-footer-tiny {
  padding: 10px 100px;
}

.modal-dialog .modal-content .modal-footer>.modal-footer-full {
  width: 100%;
  overflow: hidden;
}

.modal-dialog .modal-content .modal-footer>.modal-footer-right {
  display: inline-block;
  text-align: right;
  width: 49%;
}

.modal-dialog .modal-content .modal-footer>.modal-footer-left {
  display: inline-block;
  text-align: left;
  width: 49%;
}

.modal-xl {
  margin: 10px auto;
  width: 98%;
}

#talk-to-us.modal-footer {
  border-top: 1px solid #eee;
  padding-top: 2rem;
}

#talk-to-us .question {
  text-align: left;
  font-weight: 400;
  font-size: 0.875rem;
  color:#696969;
}

.modal-footer .btn-link-danger {
  color: #d9534f
}

/* Modal search */

.modal .modal-search {
  background-image: url(../img/icons/filter-search.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #898989;
  margin-bottom: 16px;
}

.modal .modal-search input,
.modal .modal-search input:focus {
  border: none;
  background: none;
  box-shadow: none;
  margin-bottom: 5px;
  margin-left: 30px;
  font-size: 0.875rem;
}

/* Modal Question */

.modal-dialog .modal-content .modal-header>.modal-title .warning {
  color: #ff5722;
}

.modal .question {
  display: block;
  margin-bottom: 20px;
}

/*

Layout

*******************************************/

/* Wrapper */

.wrapper {
  position: relative;
}

/* Loading cover */

.cover {
  position: fixed;
  background: #FFF;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
}

/* Mobile Splash */

.mobile-splash {
  position: fixed;
  background: #f5f5f5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  text-align: center;
  overflow: auto;
}

.mobile-splash .head {
  padding: 30px 0 0px;
  background: #fff;
}

.mobile-splash .head h4 {
  color: #55c9f0;
  font-size: 32px;
  font-weight: normal;
}

.mobile-splash .body {
  font-size: 18px;
  padding: 280px 30px 0;
  background: url(../img/mobile.svg);
  background-repeat: no-repeat;
  background-position: center 50px;
}

/* Main container */

.main {
  height: 100%;
  left: 0px;
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0;
}

.main.wide {
  left: 0 !important;
}

/* Off-Canvas Effect */

.sidebar,
.main {
  -webkit-transition: 0.2s left ease-in-out;
  transition: 0.2s left ease-in-out;
}

.sidebar-active .sidebar {
  left: 0px;
}

.sidebar-active .main {
  left: 210px;
}

/*

Notifications

*******************************************/

/* Notifications */
/*
.notification-modal>.modal-content>.modal-header {
  padding: 40px 50px 0 80px;
  background-position: 45px 45px;
  background-repeat: no-repeat;
}

.notification-modal>.modal-content>.modal-header[data-type='INFO'] {
  background-image: url(../img/icons/notification-info.png);
}

.notification-modal>.modal-content>.modal-header[data-type='TASK_OK'] {
  background-image: url(../img/icons/notification-task.png);
}

.notification-modal>.modal-content>.modal-header[data-type='BILLING'] {
  background-image: url(../img/icons/notification-billing.png);
}

.notification-modal.warning>.modal-content>.modal-header>.modal-title {
  color: #ff5722;
}

.notification-modal.warning>.modal-content>.modal-header[data-type='INFO'] {
  background-image: url(../img/icons/notification-warning-info.png);
}

.notification-modal.warning>.modal-content>.modal-header[data-type='TASK_FAILED'] {
  background-image: url(../img/icons/notification-warning-task.png);
}

.notification-modal.warning>.modal-content>.modal-header[data-type='BILLING'] {
  background-image: url(../img/icons/notification-warning-billing.png);
}*/

/*

Accounts

*******************************************/

/* Account Modal */

.account-modal ul.accounts {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 50px;
}

.account-modal ul.accounts li {
  padding: 14px 10px;
  cursor: pointer;
  background-position: right center;
}

.account-modal ul.accounts li:hover {
  background-color: #f3fbfe;
}

.account-modal ul.accounts .account-name {
  color: #00aee8;
  margin: 0;
}

.account-modal ul.accounts .account-code {
  color: #b7b7b7;
  margin: 0;
}

.account-modal ul.accounts .no-results {
  margin: 0;
}

/*

Header

*******************************************/

.header {
  background-color: #494949;
  color: #eee;
}

.messenger {
  opacity: 0;
  position: relative;
  top: -10px;
  transition: 0.2s all ease-in-out;
  -webkit-transition: 0.2s all ease-in-out;
  text-align: center;
  z-index: 9000;
  height: 0;
  font-size: 0.875rem;
}

.messenger.in {
  opacity: 1;
  top: 30px;
}

.messenger span {
  background-color: #1d1d1d;
  border-radius: 50px;
  color: #9bc63b;
  display: inline-block;
  font-size: 14px;
  padding: 7px 20px;
}

.messenger.error span {
  color: #FFB7A4;
}

/* Left Menu */

.header .left-menu {
  display: inline-block;
}

.header .left-menu .hamburguer {
  margin: 5px 10px;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 28px;
  background: none;
  border: 0;
}

.header .left-menu .account-button {
  font-size: 1rem;
  margin: 0;
  padding: 12px 26px;
  display: inline-block;
  background: none;
  border: none;
  border-right: 1px solid #3F3F3F;
  cursor: default;
  outline: none;
}

.header .left-menu .account-button.can-change {
  cursor: pointer;
  background-image: url(../img/icons/account-change.png);
  background-repeat: no-repeat;
  background-position: right 23px center;
  padding: 12px 51px 12px 26px;
  color: #ddd;
}

.header .left-menu .account-button.goto-parent {
  background-image: none;
  padding: 12px 26px;
}

.header .left-menu .account-button.can-change:hover,
.header .left-menu .account-button.can-change:focus {
  background-color: #323232;
  color: #eee;
}



/* Right Menu */

.header .right-menu {
  float: right;
  height: 48px;
  /* padding-right: 15px; */
}

.header .right-menu i.icon {
  font-size: 22px;
  color: #ddd;
  transition: color 0.2s ease;
}

.header .right-menu i.icon:hover {
  color: #fff;
}

.header .right-menu li.open i.icon {
  color: #555;
}

.header .right-menu>ul {
  margin: 0;
  list-style: none;
}

.header .right-menu>ul>li {
  float: left;
  position: relative;
  display: block;
}

.header .right-menu>ul>li>a,
.header .right-menu>ul>li>a:hover,
.header .right-menu>ul>li>a:focus,
.header .right-menu>ul>li>a:active {
  /* border-right: 1px solid #3f3f3f; */
  display: block;
  padding: 8px 15px;
  transition: background 0.1s ease;
}

.header .right-menu>ul>li>a:hover,
.header .right-menu>ul>li>a:focus {
  background: #323232;
}

.header .right-menu>ul>li.open>a,
.header .right-menu>ul>li.open>a:focus,
.header .right-menu>ul>li.open>a:hover {
  background: #FFFFFF;
}

/* Indicators */

.header .right-menu .indicator .counter {
  background-color: #ffd939;
  border-radius: 10px;
  color: #494949;
  display: block;
  font-size: 11px;
  font-weight: bold;
  height: 18px;
  left: 32px;
  /* letter-spacing: -0.1em; */
  line-height: 12px;
  margin-top: -36px;
  padding: 3px 0;
  position: absolute;
  text-align: center;
  width: 18px;
}

/*
.header .right-menu .indicator .icon {
    width: 28px;
    height: 27px;
}
*/
.header .right-menu .indicator .icon.icon-indicator-tasks {
  /*
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    */
}

/* User Session Menu */
/*
.header .right-menu .session .icon {
    width: 38px;
    height: 28px;
}
*/

.header .right-menu .session>ul>li>a>.icon {
  width: 28px;
  height: 28px;
  float: left;
  margin-right: 10px;
}

.header .right-menu .session>ul>li>a>span {
  display: block;
  margin-top: 5px;
}

/* Right Menu Dropdowns */

.header .right-menu>ul>li>ul.dropdown-menu {
  border-radius: 9px 0 9px 9px;
  padding: 10px 0 10px 0;
  margin: -1px 0 0;
  min-width: 260px;
}

.header .right-menu>ul>li>ul.dropdown-menu>li>a {
  padding: 10px 20px;
  white-space: normal;
  overflow: hidden;
  font-size: 0.875rem;
  color: #777;
  /*font-weight: bold;*/
}

.header .right-menu>ul>li>ul.dropdown-menu>li>a.danger {
  color: #ff5722;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.message {
  margin: 0;
  padding: 20px;
  text-align: center;
  color: #696969;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.title {
  font-size: 16px;
  color: #696969;
  padding: 20px 0 10px 70px;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.action {
  padding: 20px;
  text-align: center;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.user {
  font-size: 0.875rem;
  padding: 10px 0 10px 25px;
  color: #777;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.user .username {
  color: #aaa;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.user .email {
  color: #aaa;
}

/* Right Menu Dropdown Listing */

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 360px;
  overflow: auto;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li {
  background-repeat: no-repeat;
  padding-left: 70px;
  padding-right: 25px;
  cursor: pointer;
  background-position: 25px 20px;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li:hover {
  background-color: #f3fbfe;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li>.description {
  color: #696969;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 285px;
  border-top: 1px solid #eaeaea;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li>.description>.cancel {
  float: right;
  display: block;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li>.description>.remove {
  float: right;
  display: block;
  margin-top: 10px;
  margin-right: 8px;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li>.description>.remove>.icon {
  width: 12px;
  height: 12px;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li>.description>.title {
  color: #333;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li>.description>.details {
  color: #b7b7b7;
  font-size: 13px;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li>.description>.progression {
  background: #c6edfa;
  margin-top: 10px;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li>.description>.progression>.bar {
  background: #00aee8;
  height: 2px;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li>.description>.pending {
  margin-top: 10px;
  color: #b7b7b7;
  font-size: 13px;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.warning>.description>.title {
  color: #ff5722;
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.warning>.description>.details {
  color: #ffb7a4;
}

/* Tasks Types and Icons */

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.task[data-type='LIST_JOIN'] {
  background-image: url(../img/icons/task-list-join.png);
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.task[data-type='IMPORT'] {
  background-image: url(../img/icons/task-import.png);
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.task[data-type='EXPORT'] {
  background-image: url(../img/icons/task-export.png);
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.task[data-type='SENDING'] {
  background-image: url(../img/icons/task-sending.png);
}

/* Notification Types and Icons */

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.notification[data-type='INFO'] {
  background-image: url(../img/icons/notification-info.png);
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.notification[data-type='TASK_OK'] {
  background-image: url(../img/icons/notification-task.png);
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.notification[data-type='BILLING'] {
  background-image: url(../img/icons/notification-billing.png);
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.notification.warning[data-type='INFO'] {
  background-image: url(../img/icons/notification-warning-info.png);
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.notification.warning[data-type='TASK_FAILED'] {
  background-image: url(../img/icons/notification-warning-task.png);
}

.header .right-menu>ul>li>ul.dropdown-menu>li.listing>ul>li.notification.warning[data-type='BILLING'] {
  background-image: url(../img/icons/notification-warning-billing.png);
}

.header .trial {
  display: block;
  float: right;
  margin-top: 11px;
  margin-right: 15px;
}

.header .trial-banner {
  padding: 0.5rem 1.5rem;
  border-radius: 18px;
  color: #3f3f3f;
  font-size: 0.875rem;
  font-weight: 500;
}

.header .trial-banner.offer-trial {
  background-color: #e0e8b0;
}
.header .trial-banner.offer-trial:hover {
  background-color: #f0f4d9;
}

.header .trial-banner.in-trial {
  background-color: #e0e8b0;
}
.header .trial-banner.in-trial:hover {
  background-color: #f0f4d9;
}

.header .trial-banner.plan-blocked {
  background-color: #f7a427;
}
.header .trial-banner.plan-blocked:hover {
  background-color: #f9b44e;
}


/*

Sidebar

*******************************************/

.sidescroll {
  overflow: auto;
}

.sidebar {
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 210px;
  left: -210px;
  background: #f5f5f5;
  background: -moz-linear-gradient(left, #f5f5f5 98%, #E8E8E8 100%);
  background: -webkit-linear-gradient(left, #f5f5f5 98%, #E8E8E8 100%);
  background: linear-gradient(to right, #f5f5f5 98%, #E8E8E8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#E8E8E8', GradientType=1);
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: bold;
  font-size: 14px;
}

.sidebar .brand {
  display: block;
  height: 50px;
  background: #fafafa url("../img/icons/logo.svg") no-repeat center;
  margin: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background-size: 50%;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar ul li {
  overflow: hidden;
}

.sidebar ul li,
.sidebar ul li a {
  display: block;
  position: relative;
  float: none;
}

.sidebar .sidescroll ul.menu>li:first-child:before {
  display: none;
}

.sidebar .sidescroll ul>li>a {
  padding: 18px 0 20px 34px;
  /* color: #696969; */
  /* font-family: 'Open Sans', sans-serif; */
  transition: color 0.3s ease;
  font-weight: 600;
  color: #757575;
}

.sidebar .sidescroll ul>li>a:focus,
.sidebar .sidescroll ul>li>a:hover {
  color: #00AEE8;
  background: none;
}

.sidebar .sidescroll ul>li.active>a {
  color: #00AEE8;
}

.sidebar .sidescroll ul>li.open.has-childs>a {
  padding-bottom: 20px;
}

.sidebar .sidescroll ul>li>a>span {
  display: inline-block;
  margin-top: 5px;
}

.sidebar .sidescroll ul>li>ul {
  list-style: none;
  max-height: 0;
  overflow: none;
  transition: all 0.5s ease;
}

.sidebar .sidescroll ul>li.open>ul {
  /* padding-bottom: 20px; */
  max-height: 300px;
}

.sidebar .sidescroll ul>li>ul>li {
  display: block;
  position: relative;
}

.sidebar .sidescroll ul>li>ul>li:last-of-type {
  padding-bottom: 20px;
}

.sidebar .sidescroll ul>li>ul>li:after {
  display: none;
}

.sidebar .sidescroll ul>li>ul>li>a {
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
}

/*

Section Header

*******************************************/

.section-header {
  background-color: #3F3F3F;
  padding: 16px 34px 32px 28px;
}

.section-header.centered {
  text-align: center;
  padding: 30px;
}

.section-header h1 {
  color: #EEE;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section-header small {
  display: block;
  color: #aaa;
  font-size: 16px;
  font-weight: normal;
  margin-top: 5px;
}

.section-header small.lift {
  margin-left: 46px;
}

/* Filters */

.filters {
  display: inline-block;
  float: right;
  /* margin-top: 2px; */
}

.filters .filter-icon {
  vertical-align: sub;
  font-size: 1.8em;
}

.filters>.nav>.dropdown {
  min-width: 0px;
  /*
                -webkit-transition: min-width 0.3s ease;
                transition: min-width 0.3s ease;
                */
}

.filters>.nav>.dropdown>a {
  transition: color 0.1s ease-in-out;
}

.filters>.nav>.dropdown>a:hover {
  color: #ddd;
}

.filters>.nav>.dropdown.open {
  min-width: 250px;
}

.filters>.nav>.dropdown>a {
  background-repeat: no-repeat;
  color: #898989;
  font-size: 0.875rem;
  margin-right: 32px;
  padding: 0px 6px 10px 6px;
  border-radius: 0;
  min-width: 0;
}

.filters>.nav>.dropdown>a:focus,
.filters>.nav>.dropdown>a:hover,
.filters>.nav>.dropdown.open>a,
.filters>.nav>.dropdown.open>a:focus,
.filters>.nav>.dropdown.open>a:hover {
  background-color: transparent;
}

.filters>.nav>.dropdown>a>.remove {
  display: none;
  vertical-align: baseline;
}

.filters>.nav>.dropdown>a>.drop {
  vertical-align: baseline;
}

.filters>.nav>.dropdown>a.active {
  color: #EEE;
}

.filters>.nav>.dropdown>a.active>.drop {
  display: none;
}

.filters>.nav>.dropdown>a.active>.remove {
  display: inline-block;
}

.filters>.nav>.dropdown>a>span {
  display: inline-block;
  /*float: left;*/
  margin-right: 8px;
}

.filters>.nav>.dropdown>a>i {
  display: inline-block;
  padding-top: 4px;
}

.filters>.nav>.dropdown .dropdown-menu {
  /* width: 250px; */
  max-height: 400px;
  /* max-width: 250px; */
  overflow-x: hidden;
  overflow-y: auto;
  border: none;
  /* box-shadow: none; */
  background-color: #3f3f3f;
  border-radius: 0 0 9px 9px;
  margin: 0;
  padding: 0;
}

.filters>.nav>.dropdown .dropdown-menu li a {
  color: #b7b7b7;
  font-size: 0.875rem;
  padding: 12px 40px;
  border-bottom: 1px solid #353535;
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.filters>.nav>.dropdown .dropdown-menu li a:hover,
.filters>.nav>.dropdown .dropdown-menu li a:focus:active,
.filters>.nav>.dropdown .dropdown-menu li a:focus,
.filters>.nav>.dropdown .dropdown-menu li a:active {
  background-color: #353535;
}

.filters>.nav>.dropdown .dropdown-menu li a em {
  display: block;
  font-size: 12px;
}

.filters>.nav>.dropdown .dropdown-menu li a .tags {
  margin: 5px 0 0;
  display: block;
}

.filters>.nav>.dropdown .dropdown-menu li a .tags .label {
  border: 1px solid #b7b7b7;
  background: none;
  color: #b7b7b7;
}

.filters>.nav>.dropdown .spinner {
  color: white;
  padding: 20px;
}

.filters>.nav>.dropdown .spinner i {
  margin-right: 10px;
}

/* Filter Icons */

.filters>.nav>.dropdown>a.filter-list {
  /*background-image: url(../img/icons/filter-list.png);*/
}

.filters>.nav>.dropdown>a.filter-interest {
  /*background-image: url(../img/icons/filter-interest.png);*/
}

.filters>.nav>.dropdown>a.filter-state {
  /*background-image: url(../img/icons/filter-state.png);*/
}

.filters>.nav>.dropdown>a.filter-tag {
  /*    background-image: url(../img/icons/filter-tag.png);*/
}

.filters>.nav>.dropdown>a.active.filter-list {
  /*background-image: url(../img/icons/filter-list-active.png);*/
}

.filters>.nav>.dropdown>a.active.filter-interest {
  /*background-image: url(../img/icons/filter-interest-active.png);*/
}

.filters>.nav>.dropdown>a.active.filter-state {
  /*background-image: url(../img/icons/filter-state-active.png);*/
}

.filters>.nav>.dropdown>a.active.filter-tag {
  /*    background-image: url(../img/icons/filter-tag-active.png);*/
}

/* Instant Search */

.instant-search {
  padding: 12px 40px;
  position: relative;
  border-bottom: 1px solid #353535;
}

.instant-search input {
  padding: 0;
  font-size: 0.875rem;
  color: #b7b7b7;
  background: none;
  border: none;
  width: 100%;
}

.no-results {
  padding: 18px 40px;
  font-style: italic;
  color: #b7b7b7;
}

/* Search */

.search {
  margin-left: -10px;
}

.search a {
  border-radius: 0;
}

.search form {
  padding: 3px 0 5px;
  overflow: hidden;
}

.search button,
.search button:hover,
.search button:active:focus,
.search button:active,
.search button:focus {
  color: #898989;
  background: none;
  float: left;
  padding: 8px 0 0 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  transition: color 0.1s ease-in-out;
}

.search button:hover,
.search button:active:focus,
.search button:active,
.search button:focus {
  color: #DDD;
}

.search button .icon {
  background-position: -7px 0;
  height: 32px;
  width: 31px;
}

.search input,
.search input:focus {
  background: none;
  border: none;
  font-size: 0.875rem;
  box-shadow: none;
  border-radius: 0;
  float: right;
  max-width: 0;
  padding-left: 0;
  padding-right: 0;
  color: #EEE;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search form.open button {
  color: #EEE;
}

.search form.open input {
  margin-right: 28px;
  margin-left: 10px;
  max-width: 140px;
  margin-top: 3px;
}

.form-control:focus {
  box-shadow: none;
}

.search .clear-search {
  cursor: pointer;
  padding: 11px 6px 6px 6px;
  position: absolute;
  right: 0;
  z-index: 3000;
  color: #FFF;
}

/* List search */

.list-search {
  background-image: url(../img/icons/filter-search.png);
  background-repeat: no-repeat;
  border-bottom: 1px solid #898989;
  margin-bottom: 16px;
}

.list-search input,
.list-search input:focus {
  border: none;
  background: none;
  box-shadow: none;
  margin-bottom: 5px;
  margin-left: 30px;
  font-size: 0.875rem;
}

.list-search .clear-search {
  cursor: pointer;
  float: right;
  margin-top: -35px;
  padding: 6px;
  position: relative;
  color: #696969;
}

/*

Toolbar

*******************************************/

.toolbar {
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  position: relative;
  padding-left: 28px;
  height: 0;
  z-index: 3;
}

.toolbar .btn {
  background-color: #CDE08E;
  color: #3f3f3f;
  /* font-family: 'Open Sans', Helvetica, Arial, sans-serif; */
  position: relative;
  top: -19px;
  border-radius: 25px;
  padding: 10px 20px;
  box-shadow: 0 1px 2px rgba(15, 15, 21, .05), 0 2px 10px rgba(15, 15, 21, .025), 0 2px 4px rgba(0, 11, 20, .02);
  /* transition: background-color 0.05s ease; */
  z-index: 1;
  border: transparent;
}

.toolbar .btn:active,
.toolbar .btn:hover {
  background-color: #CDE08E;
  color: #3f3f3f;
  border-radius: 25px;
  padding: 10px 20px;
}

.toolbar .btn.btn-primary {
  background-color: #b8d562;
  margin-right: 15px;
}

.toolbar .btn.btn-disabled {
  background-color: lightgray!important;
  margin-right: 15px;
}

.toolbar .btn.btn-disabled:hover {
  background-color: #B7B7B7!important;
}

.toolbar .btn-group>.btn:not(:last-child) {
  margin-right: 0;
}

.toolbar .btn:hover {
  background-color: #9bc63b;
  box-shadow: 0 1px 2px rgba(15, 15, 21, .15), 0 2px 10px rgba(15, 15, 21, .035), 0 2px 4px rgba(0, 11, 20, .03);
}

.toolbar.edit-mode.lifted,
.show .toolbar.edit-mode,
form .toolbar.edit-mode {
  padding-left: 75px;
}

.toolbar.edit-mode .btn {
  background-color: #c6edfa;
}

.toolbar.edit-mode .btn[disabled] {
  opacity: 1;
}

.toolbar.edit-mode .btn.btn-primary {
  background-color: #91DCF5;
}

.toolbar.edit-mode .btn:hover {
  background-color: #70CEEF;
}

.toolbar.edit-mode .btn.btn-danger {
  color: #ff5722;
}

.toolbar .paging-selected {
  font-size: 0.875rem;
  float: right;
  padding: 0 20px;
  color: #b7b7b7;
  margin-top: 22px;
  margin-right: 34px;
}

.toolbar-wizard {
  margin-top: 20px;
}

.toolbar button .fa-lock {
  margin-left: 1rem;
}

/*

Content

*******************************************/

.content {
  overflow: auto;
  width: 100%;
}

.content.display {
  background-color: #fcfcfc;
}

.content.padded {
  padding-top: 40px;
}

/*

List Table

*******************************************/

/* List Controls */

.list-controls {
  border-bottom: 1px solid #DDD;
  padding: 24px 55px 0 21px;
  height: 68px;
  /* margin-top: 12px; */
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
}

.list-controls .check-all {
  float: left;
  height: 40px;
  width: 50px;
}

.list-controls .check-all input {
  display: none;
}

.list-controls .check-all .iconic {
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 50px;
  display: inline-block;
  background-image: url(../img/icons/lists/item-selector-unchecked.svg);
  cursor: pointer;
}

.list-controls .check-all input:checked+.iconic {
  background-image: url(../img/icons/lists/item-selector-partial.svg);
}

.list-controls .sort.dropdown {
  display: inline-block;
}

.list-controls .sort .btn

/*,
.list-controls .sort .btn:hover,
.list-controls .sort .btn:active:focus */
  {
  text-transform: none;
  background: none;
  box-shadow: none;
  font-weight: normal;
  font-size: 13px;
  color: #888;
  transition: color 0.2s ease;
}

.list-controls .sort .btn:hover {
  color: #333;
}

.list-controls .sort .btn-default.active,
.list-controls .sort .btn-default:active,
.list-controls .sort.open>.dropdown-toggle.btn-default {
  background: none;
}

.list-controls .paging-total {
  float: right;
  padding: 0 0;
  margin-right: -20px;
  color: #b7b7b7;
  /* font-family: 'Open Sans'; */
  font-size: 0.875rem;
}

/*

Empty States

*******************************************/

.empty-message {
  padding: 30px;
  margin: 0;
  color: #696969;
}

.empty-state {
  margin-top: 50px;
}

.empty-state .illustration {
  max-width: 100%;
  float: right;
}

.empty-state .info h2 {
  font-size: 1.25rem;
  font-weight: 400;
  color: #696969;
  margin-bottom: 10px;
}

.empty-state .info p {
  font-size: 0.875rem;
  color: #8B8B8B;
  margin-bottom: 20px;
}

.empty-state .info h3 {
  font-size: 0.875rem;
  color: #696969;
  margin-top: 10px;
}

.empty-state .info .links-list {
  line-height: 2;
  margin-left: 18px;
}

.empty-state .info .links-list a {
  display: list-item;
  font-size: 0.875rem;
  /* font-family: 'Open Sans', Helvetica, Arial, sans-serif; */
}

/*

Show & Edit

*******************************************/

.icon-back {
  height: 22px;
  width: 22px;
  margin-right: 17px;
}

.icon-back:hover {
  filter: invert(1);
}

.display,
.edit {
  overflow: auto;
}

.padder {
  padding: 45px 80px;
  min-height: 100%;
  max-width: 1440px;
  margin: auto;
}

/* Fields showcase */

.fields-title {
  color: #696969;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 30px;
  padding-left: 60px;
  background-repeat: no-repeat;
  padding-top: 3px;
}

.fields-title>small {
  color: #989898;
  display: block;
  font-size: 14px;
  padding-top: 6px;
  line-height: 1.3;
}

.fields-title.custom-fields {
  background-image: url(../img/icons/lists/item-field-custom.svg);
}

.fields-title.lists {
  background-image: url(../img/icons/lists/item-list.svg);
}

.fields-title.interests {
  background-image: url(../img/icons/lists/item-interest.svg);
}

.fields-title.import-file {
  background-image: url(../img/icons/import-file.png);
}

.fields-title.contact {
  background-image: url(../img/icons/lists/item-contact.svg);
}

.fields-title.campaign-description {
  background-image: url(../img/icons/campaign-description.png);
}

.fields-title.campaign-content {
  background-image: url(../img/icons/campaign-content.png);
}

.fields-title.recipients-included {
  background-image: url(../img/icons/recipients-included.png);
  margin-left: 20px;
}

.fields-title.recipients-excluded {
  background-image: url(../img/icons/recipients-excluded.png);
  margin-left: 20px;
}

.fields-title.campaign-summary {
  background-image: url(../img/icons/campaign-summary.png);
}

.fields-title.billing-history {
  background-image: url(../img/icons/billing-history.svg);
}

.fields-title.invoicing {
  background-image: url(../img/icons/invoicing.svg);
  margin-top: 30px;
}

.field-name {
  font-size: 0.875rem;
  color: #b7b7b7;
  margin-bottom: 0;
}

.field-data {
  font-size: 0.875rem;
  color: #696969;
  font-weight: 400;
  margin-bottom: 26px;
  word-wrap: break-word;
}

.field-data.empty {
  font-style: italic;
}

.default-list ul,
ul.default-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.default-list ul>li,
ul.default-list>li {
  font-size: 14px;
  color: #696969;
  padding: 5px 0;
}

.default-list ul>li:last-child,
ul.default-list>li:last-child {
  border-bottom: 0;
}

p.empty-state-message {
  color: #696969;
  font-size: 0.875rem;
}

/*

Dashboard Section

*******************************************/

.dashboard.section .section-header small {
  display: inline;
}

.dashboard.section .section-header a small:hover {
  color: #afafaf;
}

.dashboard.section .illustration {
  display: block;
  width: 100%;
  height: 700px;
  background-image: url(../img/empty-states/dashboard.svg);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center 20px;
  max-width: 1200px;
  margin: auto;
}

.dashboard.section .arrow {
  background-image: url(../img/icons/dashboard-arrow.png);
  background-repeat: no-repeat;
  bottom: 50px;
  height: 51px;
  position: absolute;
  right: 70px;
  width: 57px;
}

.dashboard .panel .panel-head {
  background: #EEE;
  padding: 20px 40px;
  margin: 0;
  font-weight: normal;
  line-height: 1;
  color: #696969;
  height: 100px;
}

.dashboard .panel .panel-head .right-link {
  color: #AAA;
  float: right;
  font-size: 14px;
}

.dashboard .panel .panel-head .right-link i {
  font-size: 9px;
  margin-left: 4px;
}

.dashboard .panel .panel-head .right-link:hover {
  color: #888;
}

.dashboard .panel .panel-head .title {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 10px;
  color: #696969;
}

.dashboard .panel .panel-head a.title:hover {
  color: #444;
}

.dashboard .panel .panel-head .subtitle {
  font-size: 14px;
  display: block;
  margin: 0;
}

.dashboard .panel .panel-head .subtitle.success {
  color: #7eb92d;
  font-style: italic;
  margin-bottom: -2px;
}

.dashboard .panel .panel-head .subtitle.error {
  color: #ff5722;
  font-style: italic;
  margin-bottom: -2px;
}

.dashboard .panel .panel-head .subtitle .icon {
  vertical-align: text-bottom;
}

.dashboard .panel .panel-head .cta {
  text-align: center;
  margin-bottom: -37px;
  margin-top: 20px;
}

.dashboard .panel .panel-head .cta .btn {
  background: #2ec5a0;
  border-radius: 25px;
  padding: 10px 20px;
  box-shadow: 0 1px 2px rgba(15, 15, 21, .05), 0 2px 10px rgba(15, 15, 21, .025), 0 2px 4px rgba(0, 11, 20, .02);
  border: transparent;
}

.dashboard .panel .panel-head .cta .btn:hover {
  background: #2bb693;
}

.dashboard .panel .panel-body {
  color: #b7b7b7;
}

.dashboard .panel .panel-body .instructions {
  font-size: 14px;
  color: #696969;
  margin: 20px;
}

.dashboard .panel .panel-body .instructions-link {
  margin: 0 20px;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.dashboard .panel .panel-body .helper {
  float: right;
  border: 1px solid #DDD;
  color: #DDD;
  border-radius: 50%;
  display: inline-block;
  width: 24px;
  text-align: center;
  margin-top: 10px;
}

.dashboard .panel .panel-body .helper:hover {
  border: 1px solid #aaa;
  color: #aaa;
}

.dashboard .panel .panel-body .stat {
  text-align: center;
  margin-bottom: 0;
}

.dashboard .panel .panel-body .stat h3 {
  font-size: 1.5rem;
  margin-top: 2rem;
}

.dashboard .panel .panel-body .stat small {
  font-size: 14px;
  color: #b7b7b7;
}

/* Dashboard: Campaign Panel */

.dashboard .dashboard-campaign.panel .panel-head {
  background-image: url(../img/dashboard/campaigns-background.svg);
}

.dashboard .dashboard-campaign.panel .panel-head .disabled {
  background-color: #e5e5e5;
  color: #FFF;
}

.dashboard .dashboard-campaign.panel .panel-body .stats {
  margin-top: 40px;
}

.dashboard .dashboard-campaign.panel .panel-body .stat {
  text-align: left;
  background-repeat: no-repeat;
  padding-left: 58px;
  margin-top: 20px;
}

.dashboard .dashboard-campaign.panel .panel-body .stat.sendings {
  background-image: url(../img/dashboard/sendings.svg);
  margin-left: 20px;
}

.dashboard .dashboard-campaign.panel .panel-body .stat.date {
  background-image: url(../img/dashboard/date.svg);
  margin-left: 20px;
}

.dashboard .dashboard-campaign.panel .panel-body .stat h3 {
  color: #2ec5a0;
  margin: 0;
  font-size: 26px;
}

.dashboard .dashboard-campaign.panel .panel-body .steps {
  list-style: none;
  padding: 0;
}

.dashboard .dashboard-campaign.panel .panel-body .steps .intro {
  margin: 0;
  font-size: 13px;
}

.dashboard .dashboard-campaign.panel .panel-body .steps li {
  background-image: url(../img/dashboard/campaign-check-empty.svg);
  background-repeat: no-repeat;
  background-position: 14px;
}

.dashboard .dashboard-campaign.panel .panel-body .steps li.available {
  background-image: url(../img/dashboard/campaign-check-hover.svg);
}

.dashboard .dashboard-campaign.panel .panel-body .steps li.completed {
  background-image: url(../img/dashboard/campaign-check-checked.svg);
}

.dashboard .dashboard-campaign.panel .panel-body .steps li a {
  display: block;
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-left: 2px solid #2ec5a0;
  padding-left: 20px;
  color: #2ec5a0;
}

.dashboard .dashboard-campaign.panel .panel-body .steps li a:hover {
  color: #2bb693;
  border-left: 2px solid #2bb693;
}

.dashboard .dashboard-campaign.panel .panel-body .steps li.unavailable a {
  color: #b7b7b7;
  border-left: 2px solid #b7b7b7;
  cursor: default;
}

.dashboard .dashboard-campaign.panel .panel-body .steps .title {
  margin: 0;
  font-size: 1.125rem;
}

.dashboard .dashboard-campaign.panel .panel-body .steps .subtitle {
  margin: 0;
  font-size: 14px;
}

.dashboard .dashboard-campaign.panel .panel-body .pie {
  text-align: center;
  font-size: 14px;
  color: #b7b7b7;
  margin-top: 40px;
  margin-bottom: 20px;
}

.dashboard .dashboard-campaign.panel .panel-body .pie .chart canvas {
  top: 0;
  left: 0;
}

.dashboard .dashboard-campaign.panel .panel-body .pie .chart .percent {
  display: inline-block;
  position: absolute;
  line-height: 100px;
  width: 100px;
  z-index: 2;
  font-size: 1.5rem;
}

.dashboard .dashboard-campaign.panel .panel-body .pie.opened .percent {
  color: #93C52D;
}

.dashboard .dashboard-campaign.panel .panel-body .pie.clicked .percent {
  color: #2FC5A0;
}

.dashboard .dashboard-campaign.panel .panel-body .pie.bounced .percent {
  color: #FFA426;
}

/* Dashboard: Plan Panel */

.dashboard .dashboard-plan.panel .panel-head {
  text-align: center;
}

.dashboard .dashboard-plan.panel.free .panel-head,
.dashboard .dashboard-plan.panel.child_limited .panel-head,
.dashboard .dashboard-plan.panel.child_uncapped .panel-head {
  background-image: url(../img/dashboard/free-background.svg);
}

.dashboard .dashboard-plan.panel.emails .panel-head {
  background-image: url(../img/dashboard/emails-background.svg);
}

.dashboard .dashboard-plan.panel.monthly .panel-head {
  background-image: url(../img/dashboard/monthly-background.svg);
}

.dashboard .dashboard-plan.panel .panel-head .btn {
  background-color: #19ace3;
}

.dashboard .dashboard-plan.panel .panel-body .consumed {
  font-size: 20px;
  color: #19ace3;
  margin: 0;
}

.dashboard .dashboard-plan.panel .panel-body .legend {
  margin-bottom: 20px;
  margin-top: 14px;
  margin-left: 10px;
  margin-right: 10px;
}

.dashboard .dashboard-plan.panel .panel-body .progress {
  margin: 5px 0;
  height: 7px;
  background: #e3f6fc;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.dashboard .dashboard-plan.panel .panel-body .progress .progress-bar {
  border-radius: 4px;
  background: #19ace3;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.dashboard .dashboard-plan.panel .panel-body .quota {
  font-size: 14px;
  color: #b7b7b7;
  margin: 0;
}

.dashboard .dashboard-plan.panel .panel-body .quota.excess {
  color: #ff5722;
}

.dashboard .dashboard-plan.panel.monthly .panel-body .legend .consumed {
  color: #FFC536;
}

.dashboard .dashboard-plan.panel.emails .panel-body .legend .consumed {
  color: #6f83d8;
}

.dashboard .dashboard-plan.panel .panel-body .legend .consumed.excess {
  color: #ff5722;
}

.dashboard .dashboard-plan.panel.monthly .panel-head .btn {
  background-color: #FFC536;
}

.dashboard .dashboard-plan.panel.monthly .panel-head .btn:hover {
  background-color: #ffb805;
}

.dashboard .dashboard-plan.panel.free .panel-head .btn,
.dashboard .dashboard-plan.panel.child_uncapped .panel-head .btn {
  background-color: #55c9f0;
}

.dashboard .dashboard-plan.panel.free .panel-head .btn:hover,
.dashboard .dashboard-plan.panel.child_uncapped .panel-head .btn:hover {
  background-color: #27bbec;
}

.dashboard .dashboard-plan.panel .panel-head .btn.disabled {
  background-color: #e5e5e5 !important;
  color: #fff;
}

.dashboard .dashboard-plan.panel.monthly .panel-body .progress {
  background: #ffefcc;
}

.dashboard .dashboard-plan.panel.monthly .panel-body .progress .progress-bar {
  background: #FFC536;
}

.dashboard .dashboard-plan.panel.emails .panel-head .btn {
  background-color: #6f83d8;
}

.dashboard .dashboard-plan.panel.emails .panel-head .btn:hover {
  background-color: #576dd1;
}

.dashboard .dashboard-plan.panel.emails .panel-body .progress {
  background: #E8ECF7;
}

.dashboard .dashboard-plan.panel.emails .panel-body .progress .progress-bar {
  background: #6f83d8;
}

.dashboard .dashboard-plan.panel .panel-body .progress.excess .progress-bar {
  background: #ff5722;
}

.dashboard .dashboard-plan.panel .panel-body .progress.excess {
  background: #ffd8cc;
}

.dashboard .dashboard-plan.panel .panel-body .progress .progress-bar-striped {
  background-image: linear-gradient(45deg, #71cdef 25%, #e3f6fc 25%, #e3f6fc 50%, #71cdef 50%, #71cdef 75%, #e3f6fc 75%, #e3f6fc);
  width: 100%;
  background-size: 40px 40px;
}

.dashboard .dashboard-plan.panel.monthly .panel-body .progress .progress-bar-striped {
  background-image: linear-gradient(45deg, #ffe49e 25%, #ffefcc 25%, #ffefcc 50%, #ffe49e 50%, #ffe49e 75%, #ffefcc 75%, #ffefcc);
  width: 100%;
  background-size: 40px 40px;
}

.dashboard .dashboard-plan.panel.emails .panel-body .progress .progress-bar-striped {
  background-image: linear-gradient(45deg, #bfc7ed 25%, #E8ECF7 25%, #E8ECF7 50%, #bfc7ed 50%, #bfc7ed 75%, #E8ECF7 75%, #E8ECF7);
  width: 100%;
  background-size: 40px 40px;
}

/* Dashboard: Lists Panel */

.dashboard .dashboard-lists.panel .panel-head {
  background-image: url(../img/dashboard/lists-background.svg);
  text-align: center;
}

.dashboard .dashboard-lists.panel .panel-head .btn {
  background-color: #55c9f0;
}

.dashboard .dashboard-lists.panel .panel-head .btn:hover {
  background-color: #27bbec;
}

.dashboard .dashboard-lists.panel .panel-body .stat h3 {
  color: #55c9f0;
}

.dashboard .dashboard-lists.panel .panel-body .stat .stars {
  margin: 30px auto 18px;
}

/* Dashboard: Forms Panel */

.dashboard .dashboard-forms.panel .panel-head {
  background-image: url(../img/dashboard/forms-background.svg);
  text-align: center;
}

.dashboard .dashboard-forms.panel .panel-head .btn {
  background-color: #b8d562;
}

.dashboard .dashboard-forms.panel .panel-head .btn:hover {
  background-color: #9bc63b;
}

.dashboard .dashboard-forms.panel .panel-body .stat h3 {
  color: #b8d562;
}

/*

Lists Section

*******************************************/

.lists.section .list ul.table>div>div.check-cell>.iconic {
  background-image: url(../img/icons/lists/item-list.svg);
}

.lists.section .edit .icon-box {
  text-align: center;
  padding: 0 15px;
}

.lists.section .edit .icon-box>.icon {
  display: block;
  height: 91px;
  margin: 0 auto;
  width: 91px;
}

.lists.section .edit .icon-box h4 {
  font-size: 22px;
}

.lists.section .edit a:hover .icon-box h4 {
  text-decoration: underline;
}

.lists.section .edit .icon-box.total-contacts h4 {
  color: #FFBF3E;
}

.lists.section .edit .icon-box p {
  font-size: 14px;
  color: #696969;
  margin: 0 20px;
}

.lists.section .edit .icon-box.contacts-quality .stars {
  margin: 15px auto;
}

.lists.section .edit .icon-box.contacts-with-opens h4 {
  color: #A7D163;
}

.lists.section .edit .icon-box.inactive-contacts h4 {
  color: #FF5722;
}

.lists.section .pie .chart canvas {
  top: 0;
  left: 0;
}

.lists.section .pie .chart .percent {
  display: inline-block;
  position: absolute;
  line-height: 100px;
  width: 100px;
  z-index: 2;
  font-size: 26px;
}

.lists.section .pie.opened .percent {
  color: #93C52D;
}

.lists.section .pie+h4 {
  margin-top: -2px;
}

.lists.section .pie.inactive .percent {
  color: #FF5722;
}

.lists.section.show .panel-body {
  padding: 30px;
}

/* Import */

.upload-progress {
  background-color: #00aee8;
}

.lists.section .import-toolbar {
  overflow: visible;
}

.lists.section .source-option {
  text-align: center;
}

.lists.section .source-option a {
  display: block;
  border-radius: 2px;
  border: 2px solid #DDD;
  padding-top: 80px;
  margin-bottom: 10px;
  padding-bottom: 16px;
  color: #696969;
  background-repeat: no-repeat;
  background-position: center 20px;
}

.lists.section .source-option a:hover {
  color: #00aee8;
  border-color: #00aee8;
}

.lists.section .source-option.file a {
  background-image: url(../img/icons/import-source-file.png);
}

.lists.section .source-option.file a:hover {
  background-image: url(../img/icons/import-source-file-hover.png);
}

.lists.section .source-option.clipboard a {
  background-image: url(../img/icons/import-source-clipboard.png);
}

.lists.section .source-option.clipboard a:hover {
  background-image: url(../img/icons/import-source-clipboard-hover.png);
}

.lists.section .source-option.meli a {
  background-image: url(../img/icons/import-source-meli.png);
  background-position: center 25px;
}

.lists.section .table-container {
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
}

.lists.section .table-container .table label {
  color: #00aee8;
}

.lists.section .table-container .table select {
  width: auto;
  width: -moz-available;
  font-weight: normal;
}

.lists.section .import-section {
  margin-bottom: 40px;
  overflow: visible;
}

.lists.section .max-length-error {
  margin-top: 20px;
}

/*

General

*******************************************/

.content-preview {
  border-radius: 2px;
  max-width: 100%;
  margin: 24px auto;
}

.content-preview .frame {
  width: 100%;
}

.content-preview.mobile .frame {
  width: 360px;
  margin: 0 auto;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.content-preview .head {
  font-size: 14px;
  color: #696969;
  padding: 16px 35px;
  border: 1px solid #DDD;
  min-height: 52px;
  border-radius: 9px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.content-preview .head .actions {
  display: inline-block;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.content-preview .head .actions a {
  text-transform: uppercase;
  margin-right: 30px;
}

.content-preview iframe {
  width: 100%;
  height: 0px;
}

.content-preview .device-switch {
  float: right;
}

.content-preview .device-switch button {
  border: none;
  color: #3f3f3f;
  background: #E3F6FC;
  width: 35px;
  outline: none !important;
}

.content-preview .device-switch button.active {
  background: #8edbf5;
}

.content-preview .device-switch button.desktop {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.content-preview .device-switch button.mobile {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/*

Contacts Section

*******************************************/

.columns-selector-button {
  color: #888;
  float: right;
  background: none;
  border: none;
  padding: 0 0 8px 30px;
  outline: none !important;
  display: inline-block;
}

/* Options Dropdown */

.list-controls .dropdown.options {
  float: right;
  margin-left: 30px;
}

.list-controls .dropdown.options .btn {
  background-image: url(../img/icons/options.png);
  background-repeat: no-repeat;
  background-position: center;
  color: transparent;
  font-size: 0;
  height: 30px;
  width: 30px;
  background-color: transparent;
}

.list-controls .dropdown.options .btn:active,
.list-controls .dropdown.options .btn:focus:active {
  box-shadow: none;
}

/* List Icon */

.contacts.section .list ul.table>div>div.check-cell>.iconic {
  background-image: url(../img/icons/lists/item-contact.svg);
}

.contacts.section .list ul.table>div>div.check-cell>.male.iconic {
  background-image: url(../img/icons/lists/item-contactm.svg);
}

.contacts.section .list ul.table>div>div.check-cell>.female.iconic {
  background-image: url(../img/icons/lists/item-contactf.svg);
}

/* Mini Avatar */

.contacts.section .mini-avatar {
  border-radius: 60px;
  height: 44px;
  width: 44px;
  margin: 3px;
  display: block;
}

.contacts.section .list ul.table>div:hover>div.check-cell>.iconic>.mini-avatar,
.contacts.section .list ul.table>div.selected>div.check-cell>.iconic>.mini-avatar {
  display: none;
}

/* State */

.contact-state {
  font-size: 15px;
  color: #ff5722;
  margin-top: 7px;
}

/* Show */

.contact-avatar {
  background-image: url(../img/icons/lists/item-contact.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 50px;
  display: inline-block;
  float: left;
}

.contact-avatar.male {
  background-image: url(../img/icons/lists/item-contactm.svg);
}

.contact-avatar.female {
  background-image: url(../img/icons/lists/item-contactf.svg);
}

.contact-main-data {
  /* margin-left: 60px; */
  font-size: 1rem;
  color: #00AEE8;
  margin-bottom: 20px;
  font-weight: 400;
}

.contact-activity {
  border: 1px solid #ddd;
  margin: 1rem 0;
  padding: 1.5rem;
  border-radius: 5px;
  font-size: 0.875rem;
}

.contact-activity-date {
  color: #aaa;
  margin-bottom: 2px;
}

.contact-activity-col-icon i {
  font-size: 2rem;
  margin: 0.6rem 2rem;
  color: #444;
}

.contact-activity-col-icon {
  width: 7.2rem;
}

.contact-activity-period {
  color: #aaa
}

.contact-activity div.interests {
  margin-top: 2px;
}

.contact-activity .interests ul {
  margin: 10px 0 0 0;
  padding: 0;
  display: inline;
}

/* .contact-activity  .interests ul li {
    display: inline-block;
} */

.contact-activity .interests ul li {
  border-radius: 50px;
  background: #edf0f7;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  font-size: 12px;
  margin-top: 6px;
}

.contact-activity .interests ul li.product {
  background: #edf0f7;
}

.contact-activity .interests ul li.category {
  background: #fff3d9;
}

.contact-activity .interests ul li.category a {
  color: #c1a353;
}

.contact-activity .interests ul li.category a:hover {
  color: #ab841e;
}

/*

Interests Section

*******************************************/

.interests.section .list ul.table>div>div.check-cell>.iconic {
  background-image: url(../img/icons/lists/item-interest.svg);
}

/*

Fields Section

*******************************************/

.fields.section .list ul.table>div>div.check-cell>.iconic {
  background-image: url(../img/icons/lists/item-field.svg);
}

.fields.section .list ul.table>div>div.check-cell>.iconic.custom {
  background-image: url(../img/icons/lists/item-field-custom.svg);
}

.fields.section .list ul.table>div>div.info-cell>strong {
  font-family: Courier, monospace;
  font-size: 17px;
  letter-spacing: 0;
  white-space: nowrap;
}

/*

Optins Section

*******************************************/

.optins.section .list ul.table>div>div.check-cell>.iconic {
  background-image: url(../img/icons/lists/item-optin.svg);
}

/*

Froms

*******************************************/

.send-instructions textarea {
  min-height: 110px;
}


/*

Accounts Section

*******************************************/

/* Account Logo */

.accounts.section .mini-avatar {
  border-radius: 60px;
  height: 44px;
  width: 44px;
  margin: 3px;
  display: block;
  background-position: center;
  background-size: cover;
}

.accounts.section .list ul.table>div:hover>div.check-cell>.iconic>.mini-avatar,
.accounts.section .list ul.table>div.selected>div.check-cell>.iconic>.mini-avatar {
  display: none;
}

/* Account Detail */

.accounts.section .edit .account-info {
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 2px #f4f4f4 solid;
  position: relative;
}

.accounts.section .avatar {
  border-radius: 35px;
  height: 70px;
  width: 70px;
  margin-right: 20px;
  float: left;
  background-color: transparent !important;
  background-size: cover;
  background-position: center;
}

.accounts.section .account-info .anchor {
  color: #fff;
  display: none;
  left: 0;
  padding-left: 7px;
  padding-top: 25px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7) !important;
  font-size: 0.75rem;
}

.accounts.section .account-info h3 {
  padding: 10px;
  margin: 0;
  font-size: 22px;
}

.accounts.section .account-info h3 small {
  display: block;
  margin-top: 5px;
  font-size: 14px;
}

.accounts.section .account-info .image-container:hover .anchor {
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
  display: block;
}

.accounts.section .account-location h3 {
  padding: 16px;
  margin: 5px 0 0;
  font-size: 14px;
}

.accounts.section .account-location h3 small {
  display: block;
  margin-top: 5px;
  font-size: 14px;
}

.accounts.section .icon-location {
  height: 60px;
  width: 60px;
  float: left;
  margin-right: 20px;
}

.change-account-link {
  cursor: pointer;
}

.change-account-link:hover {
  color: #333;
}

/* Users and roles lists */

.user-list .note,
.roles-list .note {
  color: #b7b7b7;
  font-size: 0.875rem;
}

.user-list .disabled {
  opacity: 0.5;
}

.roles-list .active a {
  margin-bottom: -2px;
}

.user-list .admin .panel {
  border-bottom: 2px #95C52E solid;
}

.roles-list .active.admin a {
  border-bottom: 2px solid #95C52E;
}

.roles-list .active.admin span,
.user-list .admin .role {
  color: #95C52E;
}

.user-list .viewer .panel {
  border-bottom: 2px #3F51B5 solid;
}

.roles-list .active.viewer a {
  border-bottom: 2px solid #3F51B5;
}

.roles-list .active.viewer span,
.user-list .viewer .role {
  color: #3F51B5;
}

.user-list .author .panel {
  border-bottom: 2px #FFCA28 solid;
}

.roles-list .active.author a {
  border-bottom: 2px solid #FFCA28;
}

.roles-list .active.author span,
.user-list .author .role {
  color: #FFCA28;
}

.user-list .manager .panel {
  border-bottom: 2px #2EC5A0 solid;
}

.roles-list .active.manager a {
  border-bottom: 2px solid #2EC5A0;
}

.roles-list .active.manager span,
.user-list .manager .role {
  color: #2EC5A0;
}

.user-list .custom .panel {
  border-bottom: 2px #FF7D24 solid;
}

.roles-list .active.custom a {
  border-bottom: 2px solid #FF7D24;
}

.roles-list .active.custom span,
.user-list .custom .role {
  color: #FF7D24;
}

.user-list .panel:hover {
  background-color: #F5F5F5;
}

.user-list .panel:hover .panel-footer {
  box-shadow: none;
  border-top: 1px #F5F5F5 solid;
}

.user-list .panel .panel-head {
  font-size: 1rem;
  color: #696969;
  height: 65px;
  font-weight: 500;
}

.user-list .panel .panel-body {
  padding: 0 15px 30px 10px;
}

.user-list .panel .panel-footer {
  position: relative;
  background-color: #F5F5F5;
  padding-top: 35px;
}

.user-list .panel .panel-footer .icon {
  background-color: #fff;
  border-radius: 39px;
  height: 50px;
  left: 10px;
  position: absolute;
  top: -25px;
  width: 50px;
  box-shadow: 0 1px 2px rgba(15, 15, 21, 0.05), 0 2px 10px rgba(15, 15, 21, 0.024), 0 2px 4px rgba(0, 11, 20, 0.02);
}

/*

Users Section

*******************************************/

.users.section .icon-user-email {
  width: 75px;
  height: 64px;
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.users.section .field-data {
  margin-bottom: 5px;
  padding-top: 14px;
  line-height: 1;
}

.users.section .user-role .icon {
  float: left;
  width: 46px;
  height: 46px;
  margin-right: 20px;
}

.users.section .user-role .name {
  font-size: 22px;
}

.users.section .user-role .name.admin {
  color: #95C52E;
}

.users.section .user-role .name.viewer {
  color: #3F51B5;
}

.users.section .user-role .name.author {
  color: #FFCA28;
}

.users.section .user-role .name.manager {
  color: #2EC5A0;
}

.users.section .user-role .name.custom {
  color: #FF7D24;
}

.users.section .user-role .description {
  font-size: 14px;
  color: #afafaf;
}

.users.section .fields-title {
  padding-left: 0;
}

.users.section .roles .permissions-list h4 {
  margin-top: 30px;
  font-weight: 300;
  font-size: 20px;
}

.users.section .roles .permissions-list small {
  color: #afafaf;
}

.users.section .role {
  margin-left: 30px;
}

.users.section .radio.graphic input[type=radio]+label:before {
  float: left;
}

.users.section .role h4 {
  display: inline-block;
  margin: 0;
}

.users.section .role p {
  color: #696969;
}

/*

Campaigns Section

*******************************************/

.campaigns.section .list ul.table>div>div.check-cell>.iconic.simple {
  background-image: url(../img/icons/lists/item-campaign.svg);
}

.campaigns.section .list ul.table>div>div.check-cell>.iconic.split_ab {
  background-image: url(../img/icons/lists/item-splitab.svg);
}

.campaigns.section .list ul.table>div>div.check-cell>.iconic.smart {
  background-image: url(../img/icons/lists/item-smart.svg);
}

.campaigns.section .subject-group .btn {
  border-radius: 0;
}

.list-controls .display-toggler {
  float: right;
  margin-left: 40px;
}

.list-controls .display-toggler a {
  padding: 5px;
  display: inline-block;
  color: #a7a7a7;
}

.list-controls .display-toggler a.active {
  color: #333;
}

.campaigns.section ul.grid {
  margin-top: 20px;
}

.automations .input-group .input-group-btn .btn,
.drips .input-group .input-group-btn .btn,
.campaigns .input-group .input-group-btn .btn {
  background: none;
  padding: 8px 10px;
  border: 1px solid #ccc;
  font-size: initial;
}

.drips .input-group .input-group-btn .dropdown-menu,
.campaigns .input-group .input-group-btn .dropdown-menu {
  max-height: 200px;
  overflow: auto;
}

.campaigns.section ul.grid li.grouped-list-label {
  display: block;
  padding: 0px 0 10px;
  color: #a7a7a7;
  width: 100%;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 13px;
  /* margin-top: 20px; */
}

.campaigns.section ul.grid li .thumbnail {
  border-bottom: 4px solid #bababa;
}

.campaigns.section ul.grid li.sent .thumbnail {
  border-bottom-color: #aacc42;
}

.campaigns.section ul.grid li.scheduled .thumbnail {
  border-bottom-color: #8b99d8;
}

.campaigns.section ul.grid li.sending .thumbnail {
  border-bottom-color: #2ec5a0;
}

.campaigns.section ul.grid li.pending_approval .thumbnail {
  border-bottom-color: #ffca28;
}

.campaigns.section ul.grid li.cancelled .thumbnail {
  border-bottom-color: #ff5722;
}

.campaigns.section ul.grid li.draft .status {
  color: #bababa;
}

.campaigns.section ul.grid li.sent .status {
  color: #aacc42;
}

.campaigns.section ul.grid li.scheduled .status {
  color: #8b99d8;
}

.campaigns.section ul.grid li.sending .status {
  color: #2ec5a0;
}

.campaigns.section ul.grid li.pending_approval .status {
  color: #ffca28;
}

.campaigns.section ul.grid li.cancelled .status {
  color: #ff5722;
}

.campaigns.section .thumbnail .preview {
  height: 200px;
  max-height: 200px;
  display: block;
}

.campaigns.section .thumbnail .overlay {
  margin-top: -200px;
  height: 200px;
  max-height: 200px;
}

.campaigns.section .grid li.selected .overlay {
  /**background-color: ;**/
  background-color: #F3FBFE;
}

.campaigns.section .grid li.selected .btn {
  color: #555;
  border-color: #555;
  display: none;
}

.campaigns.section .grid li.selected .overlay {
  color: #555;
}

.campaigns.section .grid li.selected .thumbnail.image .caption {
  border-top: 1px solid transparent;
}

.campaigns.section .thumbnail .status {
  margin-left: 40px;
  margin-left: 40px;
  text-align: left;
  padding-top: 11px;
  color: #9bc63b;
}

.campaigns.section .thumbnail .btn {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: normal;
  font-size: 12px;
  transition: all 0.2s ease;
  font-weight: bold;
  margin-top: 35px;
}

.campaigns.section .thumbnail:hover .btn,
.campaigns.section li.selected .thumbnail .btn {
  margin-top: 45px;
}

.campaigns.section .thumbnail .btn:hover {
  border-color: #FFF;
  color: #FFF;
}

.campaigns.section .thumbnail .minimetrics {
  width: 80%;
  overflow: hidden;
  margin: 20px auto 0px;
  font-size: 24px;
  font-weight: lighter;
  position: absolute;
  bottom: 15px;
  width: 100%;
}

.campaigns.section .thumbnail .minimetrics>div {
  width: 50%;
  float: left;
}

.campaigns.section .thumbnail .minimetrics>div small {
  display: block;
  font-size: 14px;
  font-weight: normal;
}

.campaigns.section .recipients-panel {
  border-left: 2px solid #9bc63b;
  margin-bottom: 40px;
}

.campaigns.section .recipients-panel.excluded {
  border-left: 2px solid #ff5722;
}

.campaigns.section .recipients-panel .radios {
  margin-left: 40px;
}

.campaigns.section .recipients-panel .radios .option {
  clear: both;
}

.campaigns.section .recipients-panel .shifted {
  margin-left: 20px;
}

.campaigns.section .recipients-panel .last-mailed .optional {
  padding-left: 60px;
}

.campaigns.section .collapsable {
  display: none;
  padding-bottom: 10px;
  padding-left: 56px;
}

.campaigns.section .collapsable.in {
  display: block;
}

.selected-items-list p {
  color: #b7b7b7;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.selected-items-list ul {
  margin: 0 0 10px 0;
  padding: 0;
}

.selected-items-list ul li {
  border-radius: 50px;
  background: #edf0f7;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  font-size: 12px;
}

.selected-items-list ul.lists li,
.lists-selector .selected-items-list ul li {
  background: #e3f6fc;
}

.selected-items-list ul.interests li,
.interests-selector .selected-items-list ul li {
  background: #fff3d9;
}

.selected-items-list ul.campaigns li,
.campaigns-selector .selected-items-list ul li {
  background: #e9fff9;
}

.campaigns.section .graphic.checkbox.interests-option {
  margin-top: 20px;
}

.campaigns.section .interests-selector {
  margin-left: 27px;
}

.campaigns.section p.campaigns-legend {
  margin-top: 20px;
  color: #00aee8;
  font-size: 14px;
  margin-bottom: 5px;
}

.campaigns.section .content-options {
  text-align: center;
  margin-bottom: 30px;
  border-top: 1px solid #DDD;
  margin-top: 20px;
  padding-top: 20px;
}

.campaigns.section .content-options a {
  display: block;
  margin-top: 145px;
  background-repeat: no-repeat;
  background-position: top center;
  width: 200px;
  margin: 0 auto;
  padding-top: 140px;
}

.campaigns.section .content-options a.eddie-content {
  background-image: url(../img/icons/eddie-content.png);
}

.campaigns.section .content-options a.template-content {
  background-image: url(../img/icons/template-content.png);
}

.campaigns.section .content-options a.html-content {
  background-image: url(../img/icons/html-content.png);
}

.campaigns.section .content-options a:hover.eddie-content {
  background-image: url(../img/icons/eddie-content-hover.png);
}

.campaigns.section .content-options a:hover.template-content {
  background-image: url(../img/icons/template-content-hover.png);
}

.campaigns.section .content-options a:hover.html-content {
  background-image: url(../img/icons/html-content-hover.png);
}

.thumbnail.image .preview {
  height: 150px;
}

.campaigns.section .campaign-navigation {
  /* overflow: auto; */
}

/* Social Header Modal */

.social-options {
  margin-left: 27px;
}

/* Summary */

.campaigns.section .campaign-summary .campaign-valid {
  color: #7eb92d;
}

.campaigns.section .campaign-summary .campaign-invalid {
  color: #ff5722;
}

.campaigns.section .campaign-summary .field-name.include {
  background: url(../img/icons/icon-plus.png) no-repeat;
  padding-left: 27px;
  padding-top: 1px;
  margin-bottom: 10px;
}

.campaigns.section .campaign-summary .field-name.exclude {
  background: url(../img/icons/icon-rest.png) no-repeat;
  padding-left: 27px;
  padding-top: 1px;
  margin-bottom: 10px;
}

.campaigns.section .campaign-summary div.launch-actions {
  margin-top: 2rem;
}

.campaigns.section .campaign-summary .launch-actions .btn {
  margin-right: 10px;
}

.campaigns.section .campaign-summary .launch-actions .btn i {
  margin-right: 5px;
}

.campaigns.section .campaign-summary .selected-items-list ul {
  margin-bottom: 26px;
}

.campaigns.section .campaign-summary .validation-result {
  padding-left: 44px;
  border-left: 2px solid #7eb92d;
}

.campaigns.section .campaign-summary .validation-result.validation-invalid {
  border-left: 2px solid #ff5722;
}

.campaigns.section .campaign-summary .validation-result h4 {
  font-size: 22px;
  color: #7eb92d;
}

.campaigns.section .campaign-summary .validation-result.validation-invalid h4 {
  color: #ff5722;
}

.campaigns.section .campaign-summary .validation-result h4 small {
  display: block;
  font-size: 14px;
  color: #696969;
  margin-bottom: 18px;
  margin-top: 5px;
}

.campaigns.section .campaign-summary .validation-result .validation-error {
  background: url(../img/icons/validation-error.png) no-repeat;
  padding-left: 30px;
  color: #696969;
}

.campaigns.section .campaign-summary .validation-result .validation-warning {
  background: url(../img/icons/validation-warning.png) no-repeat;
  padding-left: 30px;
  color: #696969;
}

.campaigns.section .campaign-summary .has-warnings {
  background: url(../img/icons/validation-warning.png) no-repeat;
  padding-left: 30px;
  color: #696969;
}

.campaigns.section .campaign-summary .cannot-send {
  color: #ff5722;
}

.campaigns.section .campaign-summary .preview {
  text-align: right;
}

.campaigns.section .campaign-summary .preview img {
  display: inline;
}

.campaigns.section [name="includeAction"],
.campaigns.section [name="excludeAction"] {
  margin-right: 1%;
  width: 49%;
  float: left;
  margin-bottom: 20px;
}

.campaigns.section [name="includeLink"],
.campaigns.section [name="excludeLink"] {
  margin-left: 1%;
  width: 49%;
  margin-bottom: 20px;
}

/* Scheduler */

.scheduler .datepicker-days .prev,
.scheduler .datepicker-days .next {
  color: #00aee8;
  padding-bottom: 20px;
  padding-top: 20px;
}

.scheduler .datepicker-days .picker-switch {
  font-size: 16px;
  color: #b7b7b7;
  font-weight: normal;
  padding-bottom: 20px;
  padding-top: 20px;
}

.scheduler .datepicker-days .day.active,
.scheduler .datepicker-days .day.active:hover {
  border-radius: 50px;
  background: #8edbf5;
  color: #333;
}

.scheduler .datepicker-days .day.today,
.scheduler .datepicker-days .day.today:hover {
  border-radius: 50px;
  background: #b7b7b7;
  color: #333;
}

.scheduler .datepicker-days .day.today.active,
.scheduler .datepicker-days .day.today.active:hover {
  background: #8edbf5;
}

.scheduler .datepicker-months .month.active,
.scheduler .datepicker-months .month.active:hover {
  border-radius: 50px;
  background: #8edbf5;
  color: #333;
}

.scheduler .datepicker-years .year.active,
.scheduler .datepicker-years .year.active:hover {
  border-radius: 50px;
  background: #8edbf5;
  color: #333;
}

/*

Metrics Section

*******************************************/

body.guest #intercom-container {
  display: none;
}

@media (max-width: 768px) {
  .metrics .padder {
    padding: 20px;
  }
}

.align-div-tooltip {
  display: flex;
  align-items: baseline;
}

.metrics.section .panel .panel-head {
  background-repeat: no-repeat;
}

.metrics.section .panel .panel-head .amount {
  float: right;
  padding-right: 18px;
}

.metrics.section .panel .panel-body h4 {
  text-align: center;
  font-size: 3rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.2;
  margin-top: -10px;
}

.metrics.section .panel .panel-body h4 small {
  display: block;
  font-size: 14px;
  margin-top: 0;
  color: #696969;
}

.metrics.section .pie {
  text-align: center;
}

.metrics.section .pie .percent {
  /* padding-top: 70px; */
  font-size: 4rem;
  font-weight: 400;
  color: #696969;
  line-height: 1;
  /* margin-bottom: -181px; */
}

.metrics.section .pie .percent sup {
  top: -10px;
  font-size: 50%;
}

.metrics.section .pie .percent .total {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.metrics.section .panel .panel-body .tricard {
  color: #696969;
  margin-top: 20px;
}

.metrics.section .panel .panel-body .tricard strong {
  display: block;
  font-size: 17px;
}

@media (max-width: 768px) {
  .metrics.section .panel.shared small {
    margin-bottom: 20px;
  }
}


/* Summary Panel */

.metrics.section .panel.summary {
  padding: 40px 40px 30px;
}

.metrics.section .panel.summary .data {
  background-repeat: no-repeat;
  min-height: 80px;
  color: #696969;
  font-size: 14px;
  padding-left: 90px;
}

.metrics.section .panel.summary .sent-date {
  color: #00aee8;
  margin-bottom: 0;
  padding-top: 10px;
}

.metrics.section .panel.summary .data strong {
  font-size: 2.25rem;
  color: #00aee8;
  font-weight: 400;
  line-height: 1;
}

.metrics.section .panel.summary .data.sent {
  background-image: url(../img/icons/metrics-sent.png);
}

.metrics.section .panel.summary .data.delivered {
  background-image: url(../img/icons/metrics-delivered.png);
}

.metrics.section .panel.summary .data.calendar {
  background-image: url(../img/icons/metrics-calendar.png);
}

.metrics.section .panel.summary .description,
.metrics.section .panel.summary .tags {
  line-height: 26px;
  min-height: 26px;
  padding-left: 36px;
  color: #8e8e8e;
  font-size: 0.875rem;
}

.metrics.section .panel.summary .description {
  background: url(../img/icons/metrics-description.png) no-repeat;
}

.metrics.section .panel.summary .tags {
  background: url(../img/icons/metrics-tags.png) no-repeat;
}

.metrics.section .panel.summary h2 {
  margin-bottom: 20px;
  margin-top: 0;
}

/* Opens Panel */

.metrics.section .panel.opens .panel-head {
  background-image: url(../img/icons/metrics-panel-opens.png);
  color: #95c52e;
}

.metrics.section .panel.opens .panel-body {
  border-bottom: 3px solid #95c52e;
}

.metrics.section .pie.opened .percent {
  color: #93C52D;
}

.metrics.section .panel.opens .panel-body .tricard strong {
  color: #95c52e;
}

/* Clicks Panel */

.metrics.section .panel.clicks .panel-head {
  background-image: url(../img/icons/metrics-panel-clicks.png);
  color: #2ec5a0;
}

.metrics.section .panel.assists .panel-head {
  background-image: url(../img/icons/metrics-panel-assists.png);
  color: #FEC223;
}

.metrics.section .panel.assists .panel-body {
  border-bottom: 3px solid #FEC223;
}

.metrics.section .panel.clicks .panel-body {
  border-bottom: 3px solid #2ec5a0;
}

.metrics.section .panel.assists .panel-body {
  border-bottom: 3px solid #FEC223;
}

.metrics.section .pie.clicked .percent {
  color: #2FC5A0;
}

.metrics.section .pie.assisted .percent {
  color: #FEC223;
}

.metrics.section .panel.clicks .panel-body .tricard strong {
  color: #2ec5a0;
}

.metrics.section .panel.assists .panel-body .tricard strong {
  color: #FEC223;
}

/* Timeline Panel */

.metrics.section .panel.timeline .panel-head {
  background-image: url(../img/icons/metrics-panel-timeline.png);
  color: #696969;
}

.metrics.section .panel.timeline .panel-body {
  border-bottom: 3px solid #696969;
}

.metrics.section .panel.timeline canvas {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

/* Bounced Panel */

.metrics.section .panel.bounced .panel-head {
  background-image: url(../img/icons/metrics-panel-bounced.png);
  color: #ffa426;
}

.metrics.section .panel.bounced .panel-body {
  border-bottom: 3px solid #ffa426;
}

.metrics.section .pie.bounced .percent {
  color: #ffa426;
  padding-top: 40px;
  font-size: 3rem;
  margin-bottom: -117px;
}

.metrics.section .pie.bounced .percent .total {
  margin-top: 0;
}

.metrics.section .panel.bounced .panel-body .tricard strong {
  color: #ffa426;
}

/* Unsuscribed Panel */

.metrics.section .panel.unsubscribed .panel-head {
  background-image: url(../img/icons/metrics-panel-unsubscribed.png);
  color: #ff7d24;
}

.metrics.section .panel.unsubscribed .panel-body {
  border-bottom: 3px solid #ff7d24;
}

.metrics.section .panel.unsubscribed .panel-body h4 {
  color: #ff7d24;
}

/* Complains Panel */

.metrics.section .panel.complained .panel-head {
  background-image: url(../img/icons/metrics-panel-complained.png);
  color: #ff5722;
}

.metrics.section .panel.complained .panel-body {
  border-bottom: 3px solid #ff5722;
}

.metrics.section .panel.complained .panel-body h4 {
  color: #ff5722;
}

/* Shared Panel */

.metrics.section .panel.shared .panel-head {
  background-image: url(../img/icons/metrics-panel-shared.png);
  color: #3f51b5;
}

.metrics.section .panel.shared .panel-body {
  border-bottom: 3px solid #3f51b5;
}

.metrics.section .panel.shared .panel-body h4 {
  color: #3f51b5;
  font-size: 40px;
}

/* Links Panel */

.metrics.section .panel.links .info {
  border-bottom: 1px solid #DDD;
  padding: 30px 30px 0;
}

.metrics.section .panel.links .info .data {
  background-repeat: no-repeat;
  min-height: 85px;
  padding-left: 85px;
  padding-top: 14px;
  color: #696969;
  font-size: 0.875rem;
}

.metrics.section .panel.links .info .data strong {
  font-size: 1.125rem;
  color: #00aee8;
  font-weight: 400;
  line-height: 1;
}

.metrics.section .panel.links .info .data.yes {
  background-image: url(../img/icons/metrics-links-yes.png);
}

.metrics.section .panel.links .info .data.yes strong {
  color: #95c52e;
}

.metrics.section .panel.links .info .data.no {
  background-image: url(../img/icons/metrics-links-no.png);
}

.metrics.section .panel.links .info .data.no strong {
  color: #ff5722;
}

.metrics.section .panel.links .info .data.total {
  background-image: url(../img/icons/metrics-links-total.png);
}

.metrics.section .panel.links .list {
  padding: 10px 30px 20px 30px;
}

.metrics.section .panel.links .list ul.table>div>div.main-cell>.name>p {
  font-size: 1rem;
  margin: 0;
}

.metrics.section .panel.links .list ul.table>div>div.main-cell>.name>a {
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.25rem;
}

@media (max-width: 640px) {
  .metrics.section .panel.links .list ul.table>div>div.main-cell>.name>a {
    max-width: 100px;
  }
}

.metrics.section .panel.links .list ul.table>div>div.info-cell strong {
  font-size: 18px;
}

.metrics.section .panel.links .list ul.table>div>div.info-cell:last-child {
  padding-right: 0;
}

/* Activity Panel */

.metrics.section .panel.activity .filtering {
  list-style: none;
  border-bottom: 1px solid #ddd;
  padding: 0 30px;
}

.metrics.section .panel.activity .filtering li {
  display: inline-block;
  padding: 0 .3rem;
}

.metrics.section .panel.activity .filtering li strong {
  display: block;
  font-size: 1.25rem;
  font-weight: 400;
}

.metrics.section .panel.activity .nav>li>a {
  color: #696969;
  padding: 10px 5px;
  background-color: transparent !important;
}

.metrics.section .panel.activity .nav>li>a .fa {
  font-size: 10px;
}

.metrics.section .panel.activity .nav .dropdown-menu {
  width: 100%;
}

.metrics.section .panel.activity .list {
  padding: 10px 30px 20px 30px;
}

.metrics.section .panel.activity .filtering li a {
  text-align: center;
  min-width: 108px;
  min-height: 110px;
  padding-top: 45px;
  display: block;
  border-bottom: 2px solid transparent;
  margin-bottom: -1px;
}

.metrics.section .panel.activity .filtering li a small {
  color: #696969;
  font-size: 14px;
}

.metrics.section .panel.activity .filtering li.sent a {
  color: #00aee8;
}

.metrics.section .panel.activity .filtering li.sent.active a {
  border-color: #00aee8;
}

.metrics.section .panel.activity .filtering li.conversions a {
  color: #00aee8;
}

.metrics.section .panel.activity .filtering li.conversions.active a {
  border-color: #00aee8;
}

.metrics.section .panel.activity .filtering li.opened a {
  color: #95c52e;
}

.metrics.section .panel.activity .filtering li.opened.active a {
  border-color: #95c52e;
}

.metrics.section .panel.activity .filtering li.unopened a {
  color: #696969;
}

.metrics.section .panel.activity .filtering li.unopened.active a {
  border-color: #696969;
}

.metrics.section .panel.activity .filtering li.clicks a {
  color: #2ec5a0;
}

.metrics.section .panel.activity .filtering li.assists a {
  color: #FEC223;
}

.metrics.section .panel.activity .filtering li.clicks.active a {
  border-color: #2ec5a0;
}

.metrics.section .panel.activity .filtering li.bounced a {
  color: #ffa426;
}

.metrics.section .panel.activity .filtering li.bounced.active a {
  border-color: #ffa426;
}

.metrics.section .panel.activity .filtering li.unsubscribed a {
  color: #ff7d24;
}

.metrics.section .panel.activity .filtering li.unsubscribed.active a {
  border-color: #ff7d24;
}

.metrics.section .panel.activity .filtering li.complained a {
  color: #ff5722;
}

.metrics.section .panel.activity .filtering li.complained.active a {
  border-color: #ff5722;
}

.metrics.section .panel.activity .filtering li.shared a {
  color: #3f51b5;
}

.metrics.section .panel.activity .filtering li.shared.active a {
  border-color: #3f51b5;
}

.metrics.section .panel.activity .list ul.table {
  padding: 0;
}

.metrics.section .panel.activity .list ul.table>div>div.main-cell>.name>a {
  font-size: 1rem;
}

.metrics.section .panel.activity .list ul.table>div>div.main-cell>.name>p {
  font-size: 14px;
}

.metrics.section .panel.activity .list ul.table>div>div.info-cell strong {
  font-size: 0.875rem;
}

.metrics.section .panel.activity .list ul.table>div>div.info-cell:last-child {
  padding-right: 0;
}

.metrics.section .panel.activity .deleted-email {
  font-size: 1rem;
}

.metrics.section .panel.activity .deleted-email-legend {
  color: #bbb;
  font-style: italic;
}

/* Contents Panel */

.metrics.section .panel.contents {
  color: #696969;
  padding: 30px 60px;
}

.metrics.section .panel.contents .subject,
.campaigns.section .campaign-summary .subject,
  {
  font-size: 1.3rem;
}

.metrics.section .panel.contents .from,
.campaigns.section .campaign-summary .from {
  font-size: 1rem;
}

.metrics.section .version-selector {
  margin-bottom: 20px;
}

/* Integrations */

body.hide-intercom #intercom-frame {
  display: none;
}

body.hide-intercom #intercom-container {
  display: none;
}

.integrations .thumbnail.integration {
  border-bottom: 4px solid #ccc;
  /*height: 230px;*/
}

.integrations .thumbnail.integration.enabled {
  border-bottom: 4px solid #0E84B6;
}

.integrations .thumbnail.integration .preview {
  height: 150px;
  background-position: center;
  display: block;
}

.integrations.show .thumbnail.integration {
  margin-top: 5px;
}

.integrations h2 {
  font-size: 1.25rem;
  font-weight: normal;
  color: #696969;
  margin-bottom: 20px;
  margin-top: 0;
}

.integrations h3 {
  font-size: 24px;
  font-weight: light;
  color: #696969;
  /* margin-bottom: 20px;
    margin-top: 0; */
}

.integrations p {
  font-size: 0.875rem;
  color: #696969;
  margin-top: 10px;
}

.integrations li {
  font-size: 0.875rem;
  color: #696969;
}

.integrations .suggestion {
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 0;
}

/*
.integrations .suggestion a{
    color: #BBB;
}

.integrations .suggestion a:hover{
    color: #AAA;
} */

.integrations ul {
  padding-left: 2rem;
  padding-right: 2rem;
  list-style: circle;
}

.integrations .transactional-plan .progress {
  margin: 5px 0;
  height: 7px;
  background: #e3f6fc;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.integrations .transactional-plan .progress .progress-bar {
  border-radius: 4px;
  background: #19ace3;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.integrations .transactional-plan .consumed {
  font-size: 16px;
  color: #696969;
}

.integrations .transactional-plan .legend {
  color: #888;
}

/*

Drips Section

*******************************************/

.drips .campaign-valid {
  color: #7eb92d;
}

.drips .launch-actions .btn:first-child {
  margin-right: 10px;
}

.drips.section .list ul.table>div>div.check-cell>.iconic {
  background-image: url(../img/icons/lists/item-drip.svg);
}

.drips .dots-cell {
  text-align: left !important;
}

.drips .dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.drips .dot.sent {
  background-color: green;
}

.drips .dot.skiped {
  background-color: yellow;
}

.drips .dot.pending {
  background-color: grey;
}

.drips .dot.failed {
  background-color: red;
}

.flow {
  cursor: pointer;
}

.flow .thumbnail {
  margin-right: 0;
}

.flow .thumbnail .preview {
  background-size: contain;
  margin: 20px;
}

.flow-preview {
  height: 150px;
  background-size: contain;
  background-position-x: 0;
  background-repeat: no-repeat;
}

.drips .bootstrap-datetimepicker-widget {
  margin-top: 20px;
}

.drips .node-state-icon {
  vertical-align: baseline;
  margin-left: 5px;
}

/*

Plan Section

*******************************************/

/* Type */

.plan .plan-type.panel .panel-head {
  text-align: center;
  padding: 20px 0;
  margin: 0;
  font-weight: bold;
  font-size: 20px;
}

.plan .plan-type.panel .panel-body {
  background-repeat: no-repeat;
  min-height: 152px;
  padding-left: 235px;
  background-position: 90px 0;
  padding-top: 0;
}

.plan .plan-type.panel .panel-footer {
  padding: 20px 40px;
  color: #696969;
  font-size: 0.875rem;
}

.plan .plan-type.panel .panel-footer small.striked {
  text-decoration: line-through;
}

.plan .plan-type.panel .panel-footer .btn {
  margin: 0 auto;
  display: block;
  margin-top: -40px;
  border-radius: 30px;
  margin-bottom: 10px;
  border: transparent;
}

.plan .plan-type.panel .panel-body .limit {
  font-size: 14px;
  color: #696969;
  margin-bottom: 16px;
}

.plan .plan-type.panel .panel-body .limit strong {
  font-size: 1.5rem;
  display: block;
  font-weight: 400;
  line-height: 1.1;
}

/* Availables */

.plan .plan-availables.panel .panel-footer .state {
  padding-top: 0;
  padding-bottom: 4px;
  font-size: 0.875rem;
}

.plan .plan-availables.panel .panel-footer .state .legend {
  color: #696969;
  padding-top: 8px;
  padding-left: 90px;
  padding-bottom: 14px;
  max-width: 90%;
}

.plan .plan-availables.panel .panel-footer .state .legend h3 {
  margin: 11px 0 6px 0;
  font-size: 1.125rem;
  font-weight: 400;
}

.plan .plan-availables.panel .panel-footer {
  background: #FFF;
}

.plan .plan-availables.panel .panel-footer {
  border-bottom: 5px solid #95c52e;
}

.plan .plan-availables.panel .panel-footer.disabled {
  border-bottom: 5px solid #ff5722;
}

.plan .plan-availables.panel .panel-footer .state .legend.disabled h3 {
  color: #FF5722;
}

.plan .plan-availables.panel .panel-footer .state .legend.active h3 {
  color: #7eb92d;
}

.plan .plan-availables.panel .panel-footer .state .icon {
  width: 75px;
  height: 64px;
  display: inline-block;
  float: left;
}

.plan .plan-availables.panel .panel-body {
  padding-bottom: 0;
}

.plan .plan-availables.panel .available {
  min-height: 70px;
  font-size: 1.125rem;
  color: #696969;
  padding-left: 10px;
  padding-right: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.plan .plan-availables.panel .available.excess {
  color: #ff5722;
}

.plan .plan-availables.panel .available.excess .progress {
  background: #ffd8cc !important;
}

.plan .plan-availables.panel .available.excess .progress .progress-bar {
  background: #ff5722 !important;
}

.plan .plan-availables.panel .available .icon {
  width: 74px;
  height: 64px;
  float: left;
}

.plan .plan-availables.panel .available .legend {
  font-weight: normal;
  padding-top: 10px;
  padding-left: 92px;
  font-size: 1rem;
}

.plan .plan-availables.panel .available .legend .amount {
  float: right;
  font-size: 16px;
  font-weight: normal;
}

.plan .plan-availables.panel .available .progress {
  height: 7px;
  margin-top: 8px;
}

.plan .plan-availables.panel .available .progress .progress-bar {
  border-radius: 4px;
}

/* Change plan */

.plan .change-plan .icon-plan-to-monthly {
  height: 131px;
  width: 131px;
  float: right;
}

.plan .change-plan .panel-body {
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 5px solid #ffa426;
}

.plan .change-plan h3 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 5px;
  text-align: center;
}

.plan .change-plan p {
  font-size: 14px;
  color: #696969;
  /* max-width: 600px; */
  margin-bottom: 1rem;
  text-align: center;
}

.plan .change-plan .btn {
  background-color: #ffa426;
  border:#ffa426;
  border-radius: 30px;
}

/* Invoices */

.plan .invoices .panel .panel-body {
  padding-left: 30px;
  padding-right: 30px;
}

.plan .invoices .panel .panel-body .empty-invoices {
  max-width: 400px;
  margin: 0 auto;
}

.plan .invoices .panel .panel-body .empty-invoices .icon {
  float: left;
  width: 193px;
  height: 137px;
}

.plan .invoices .panel .panel-body .empty-invoices p {
  padding-top: 40px;
  padding-left: 20px;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: normal;
  max-width: 50%;
  line-height: 1.3;
  color: #696969;
}

.plan .invoices .list ul.table>div>div {
  padding: 18px 20px;
}

.plan .invoices .list ul.table>div>div.main-cell>.name>p {
  color: #b7b7b7;
}

.plan .invoices .list ul.table>div>div.info-cell>strong {
  font-size: 18px;
}

.plan .invoices .list ul.table>div>div.info-cell:last-of-type {
  text-align: center;
  font-weight: bold;
}

.plan .invoices .btn {
  width: 100px;
  margin-bottom: 5px;
}

.plan .invoices .list ul.table>div>div.info-cell .paid {
  color: #7eb92d;
  margin-bottom: 0.5rem;
}

.plan .invoices .list ul.table>div>div.info-cell .pending {
  color: #ffa426;
  margin-bottom: 0.5rem;
}

.plan .invoices .list ul.table>div>div.info-cell .expired {
  color: #ff5722;
  margin-bottom: 0.5rem;
}

/* Plan Chooser */

.plan-chooser {
  text-align: center;
  padding-bottom: 26px;
}

.plan .plan-chooser {
  background: #f5f5f5;
}

.plan-chooser>h3 {
  font-size: 22px;
  font-weight: bold;
  color: #696969;
  margin-top: 30px;
}

.plan-chooser>p {
  color: #696969;
  margin-bottom: 25px;
}

/*
.plan-chooser .panel {
   cursor: pointer;
}*/

.plan-chooser .panel-body {
  margin-top: 25px;
}

/* Payment Options */

.payment-options {
  text-align: center;
  min-height: 200px;
}

.payment-options .transfer-message-action {
  overflow: hidden;
  margin-top: 80px;
  margin-bottom: 30px;
}

.upgrade-option {
  /* text-align: center; */
  padding-bottom: 50px;
  padding-top: 10px;
}

.upgrade-option h3 {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.25rem;
}

/* Products modal */

.select-product .modal-content .modal-body .panel-footer .btn {
  margin: 0 auto;
  display: block;
  margin-top: -30px;
  border-radius: 30px;
  margin-bottom: 10px;
  border: transparent;
}

.select-product .modal-content .modal-body .panel-footer {
  text-align: center;
}

.select-product .modal-content .modal-body .panel-footer p {
  font-size: 14px;
  color: #696969;
  margin-bottom: 10px;
}

.select-product .modal-content .modal-body .product-selection {
  display: none;
}

.select-product .modal-content .modal-body .panel-slider .panel-body {
  padding: 30px 30px 20px;
}

.select-product .modal-content .modal-body .panel-slider .panel-body p {
  margin-bottom: 10px;
}

.select-product .modal-content .modal-body .panel-slider .panel-body .product-type {
  color: #696969;
}

.select-product .modal-content .modal-body .panel-slider .panel-body .slider.slider-horizontal {
  width: 100%;
}

.select-product .modal-content .modal-body .panel-slider input {
  font-size: 22px;
  border: 1px solid #b7b7b7;
  padding: 6px 12px;
  height: auto;
  box-shadow: none;
  margin-top: 20px;
}

.select-product .modal-content .modal-body .actions-container {
  margin-bottom: 30px;
}

.select-product .modal-content .modal-body .actions-container .cancel-subscription {
  font-weight: 500;
  font-size: 0.875rem;
  float: left;
  display: inline-block;
}

.select-product .modal-content .modal-body .panel-product .panel-body .icon {
  width: 75px;
  height: 64px;
  float: left;
  margin-left: 10px;
  margin-right: 10px;
}

.select-product .modal-content .modal-body .panel-product .panel-body .legend {
  font-size: 1.25rem;
  color: #696969;
  font-weight: 400;
  line-height: 1.1;
  padding-top: 8px;
  margin-bottom: 37px;
}

.select-product .modal-content .modal-body .panel-product .panel-body .legend.mini {
  font-size: 18px;
}

.select-product .modal-content .modal-body .panel-product .panel-body .legend small {
  font-size: 14px;
  color: #696969;
  display: block;
}

.select-product .modal-content .modal-body .panel-product .panel-footer .price {
  font-weight: 400;
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.3;
}

.select-product .modal-content .modal-body .panel-product .panel-footer .price small {
  font-weight: 400;
  font-size: 1rem;
  display: block;
  text-decoration: line-through;
  color: #9e9e9e;
}

.select-product .modal-content .modal-body .custom-product .panel-product .panel-body {
  text-align: center;
}

.select-product .modal-content .modal-body .custom-product .panel-product .panel-body .icon {
  width: 118px;
  height: 81px;
  margin: 38px auto 37px;
  float: none;
}

.select-product .modal-content .modal-body .panel-product .panel-footer .question {
  font-weight: 400;
  margin: 0;
  font-size: 1rem;
  padding: 11px 11px 12px 11px;
}

.select-product .modal-content .modal-body .payment-summary {
  border-bottom: 1px solid #DDD;
  margin-bottom: 36px;
  padding-bottom: 20px;
}

.select-product .modal-content .modal-body .payment-summary .icon {
  width: 75px;
  height: 64px;
  float: left;
  margin-right: 5px;
}

.select-product .modal-content .modal-body .payment-summary {
  font-size: 1.25rem;
  font-weight: 400;
}

.select-product .modal-content .modal-body .payment-summary .detail {
  padding-top: 10px;
  line-height: 1.1;
}

.select-product .modal-content .modal-body .payment-summary .detail small {
  font-size: 14px;
  display: block;
}

.select-product .modal-content .modal-body .payment-summary .detail.price small {
  color: #696969;
  margin-bottom: 5px;
}

.select-product .modal-content .modal-body .payment-summary .detail.price small.striked {
  text-decoration: line-through;
}

.select-product .modal-content .modal-body .payment-options>h3 {
  font-size: 24px;
  font-weight: 400;
}

.select-product .modal-content .modal-body .payment-options>p {
  font-size: 1rem;
  color: #696969;
  font-weight: 400;
}

.select-product .modal-content .modal-body .payment-type .icon {
  width: 131px;
  height: 131px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.select-product .modal-content .modal-body .payment-type.creditcard .panel-footer {
  border-bottom: 5px solid #00AFE8;
}

.select-product .modal-content .modal-body .payment-type.creditcard .panel-footer .btn {
  background: #00AFE8;
}

.select-product .modal-content .modal-body .payment-type.transfer .panel-footer {
  border-bottom: 5px solid #7EB92D;
}

.select-product .modal-content .modal-body .payment-type.transfer .panel-footer .btn {
  background: #7EB92D;
}

.select-product .modal-content .modal-body .payment-disclaimer {
  margin-bottom: 30px;
  color: #696969;
}

.select-product .modal-content .modal-body .padding-spacing{
  padding: 0.5rem;
}

.select-product .slider-handle {
  top: 0!important;
}

.select-limit .modal-content .modal-body .limit-type {
  text-align: center;
  margin-bottom: 40px;
}

.select-limit .modal-content .modal-body .limit-type .icon {
  width: 75px;
  height: 64px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.select-limit .modal-content .modal-body .limit-type .panel-footer .btn {
  background: #ff7d24;
  border: #ff7d24;
  margin: 0 auto;
  display: block;
  margin-top: -30px;
  border-radius: 30px;
  margin-bottom: 10px;
}

.select-limit .modal-content .modal-body .limit-type .panel-footer .btn[disabled] {
  background: #DDD;
  color: #FFF;
}

.select-limit .modal-content .modal-body .limit-type .panel-footer {
  border-bottom: 5px solid #ff7d24;
}

.select-limit .modal-content .modal-body .limit-type .panel-footer.disabled {
  border-color: #DDD;
}

/* WIP */

.payment-success .modal-content .modal-body .icon-plan-payment-success {
  width: 150px;
  height: 158px;
  float: right;
}

.payment-success .modal-content .modal-body .legend {
  /* font-weight: lighter; */
}

.payment-success .modal-content .modal-body .legend .btn {
  background: url(../img/icons/download-arrow.png) no-repeat 15px 9px;
  padding-left: 40px;
}

.payment-success .modal-content .modal-body form {
  margin-top: 30px;
}

.payment-success .modal-content .modal-body .form-group:last-child {
  margin-bottom: 10px;
}


.plan .progress .progress-bar {
  box-shadow: none;
}

/* Free Plan */

.plan .plan-type.panel.free strong {
  color: #6f83d8;
}

.plan .plan-type.panel.free .panel-body {
  background-image: url(../img/icons/plan-free.svg);
}

.plan .plan-type.panel.free .panel-footer .btn {
  background-color: #6f83d8;
}

.plan .plan-type.panel .panel-footer .free-billing {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 0.875rem;
}

.plan .plan-availables.panel.free .available .progress {
  background-color: #e8ecf7;
}

.plan .plan-availables.panel.free .available .progress .progress-bar {
  background-color: #6f83d8;
}

/* Monthly Plan */

.plan .plan-type.panel.monthly strong {
  color: #ffa426;
}

.plan .plan-type.panel.monthly .panel-body {
  background-image: url(../img/icons/plan-monthly.svg);

}

.plan .plan-chooser .plan-type.panel.monthly .panel-body {
  background-position: center 0;
}

.plan .plan-type.panel.monthly .panel-footer .btn,
.plan .plan-type.panel .panel-footer button.btn-monthly.btn {
  background-color: #ffa426;
}

.plan .plan-type.panel .panel-footer .monthly-billing .price {
  float: left;
  line-height: 1.3;
}

.plan .plan-type.panel .panel-footer .monthly-billing .price strong {
  font-size: 2.25rem;
  display: block;
  font-weight: 400;
}

.plan .plan-type.panel .panel-footer .monthly-billing .price small {
  font-size: 14px;
  color: #b7b7b7;
}

.plan .plan-availables.panel.monthly .available .progress {
  background-color: #ffeec2;
}

.plan .plan-availables.panel.monthly .available .progress .progress-bar {
  background-color: #ffa426;
}

.plan .plan-chooser .monthly .panel-footer {
  border-bottom: 5px solid #ffa426;
}

.plan .plan-chooser .monthly:hover .panel-footer {
  border-bottom: 5px solid #ff7d24;
}

.plan .plan-chooser .monthly:hover .panel-footer .btn {
  background-color: #ff7d24;
}

.select-product.monthly .slider-selection {
  background: #ffa426;
}

.select-product.monthly .slider-handle {
  border: 3px solid #ffa426;
  background: #FFF;
}

.select-product.monthly .slider-track {
  background: #ffeec2;
}

.select-product.monthly .modal-content .modal-body .panel-product .panel-body {
  background: #f5f5f5;
}

.select-product.monthly .modal-content .modal-body .panel-product .panel-footer {
  background: #fff;
}

.select-product.monthly .modal-content .modal-body .panel-slider input {
  color: #ff7d24;
}

.select-product.monthly .modal-content .modal-body .selected .panel-product .panel-body {
  background: #ffeecc;
}

.select-product.monthly .modal-content .modal-body .selected .panel-product .panel-footer .price {
  color: #ffa426;
}

.select-product.monthly .modal-content .modal-body .disabled .panel-product .panel-body,
.select-product.monthly .modal-content .modal-body .current .panel-product .panel-body {
  background: #f5f5f5;
}

.select-product.monthly .modal-content .modal-body .disabled .panel-product .panel-footer .price,
.select-product.monthly .modal-content .modal-body .current .panel-product .panel-footer .price {
  color: #696969;
}

.select-product.monthly .modal-content .modal-body .disabled .panel-product .panel-footer .btn,
.select-product.monthly .modal-content .modal-body .current .panel-product .panel-footer .btn {
  background: #f5f5f5;
  color: #999;
}

.select-product .modal-content .modal-body .panel-product.monthly .panel-footer .btn {
  background-color: #ffa426;
}

.select-product .modal-content .modal-body .payment-summary.monthly .detail.price {
  color: #ffa426;
}

/* Emails Plan */

.plan .plan-type.panel.emails strong {
  color: #4eccbc;
}

.plan .plan-type.panel.emails .panel-body {
  background-image: url(../img/icons/plan-packs.svg);
  background-position: center 0;
}

.plan .plan-type.panel.emails .panel-body .limit strong {
  padding: 14px 0 0 0;
}

.plan .change-plan .plan-type.panel .panel-body{
  background-position: 50% 1rem;
  min-height: 170px;
  border-bottom: none;
}


.plan .plan-type.panel.emails .panel-footer .btn {
  background-color: #4eccbc;
}

.plan .plan-type.panel .panel-footer .emails-billing {
  padding-top: 10px;
  padding-bottom: 11px;
}

.plan .plan-availables.panel.emails .available .progress {
  background-color: #e9fffa;
}

.plan .plan-availables.panel.emails .available .progress .progress-bar {
  background-color: #4eccbc;
}

.plan .plan-availables.panel.free .available .progress .progress-bar.progress-bar-striped {
  background-image: linear-gradient(45deg, #71cdef 25%, #e3f6fc 25%, #e3f6fc 50%, #71cdef 50%, #71cdef 75%, #e3f6fc 75%, #e3f6fc);
  width: 100%;
}

.plan .plan-availables.panel.emails .available .progress .progress-bar.progress-bar-striped {
  background-image: linear-gradient(45deg, #e9fff9 25%, #c6f4e6 25%, #c6f4e6 50%, #e9fff9 50%, #e9fff9 75%, #c6f4e6 75%, #c6f4e6);
  width: 100%;
}

.plan .plan-availables.panel.monthly .available .progress .progress-bar.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffe49e 25%, #ffefcc 25%, #ffefcc 50%, #ffe49e 50%, #ffe49e 75%, #ffefcc 75%, #ffefcc);
  width: 100%;
}

.plan .plan-chooser .emails .panel-footer {
  border-bottom: 5px solid #4eccbc;
}

.plan .plan-chooser .emails:hover .panel-footer {
  border-bottom: 5px solid #49c1b0;
}

.plan .plan-chooser .emails:hover .panel-footer .btn {
  background-color: #49c1b0;
}

.select-product.pack .slider-selection {
  background: #4eccbc;
}

.select-product.pack .slider-handle {
  border: 3px solid #4eccbc;
  background: #FFF;
}

.select-product.pack .slider-track {
  background: #EAFFFA;
}

.select-product.pack .modal-content .modal-body .panel-slider input {
  color: #4eccbc;
}

.select-product.pack .modal-content .modal-body .selected .panel-product .panel-footer .price {
  color: #4eccbc;
}

.select-product.pack .modal-content .modal-body .selected .panel-product .panel-body {
  background: #EAFFFA;
}

.select-product .modal-content .modal-body .panel-product.emails .panel-footer .btn {
  background-color: #4eccbc;
}

.select-product .modal-content .modal-body .payment-summary.emails .detail.price {
  color: #4eccbc;
}

.select-product .modal-content .modal-body .custom-product .panel-product.emails .panel-body .icon {
  margin: 0 auto;
}

.select-product .modal-content .modal-body .panel-product.emails .panel-footer .question {
  font-size: 20px;
  padding: 15px 11px;
}

/*

Icons

*******************************************/

.icon {
  display: inline-block;
  background-repeat: no-repeat;
}

.sidebar .icon {
  width: 36px;
  height: 30px;
  margin-right: 12px;
  float: left;
}

.icon-indicator-tasks {
  background-image: url(../img/icons/indicator-tasks.png);
}

.open>a>.icon-indicator-tasks {
  background-image: url(../img/icons/indicator-tasks-active.png);
}

.icon-indicator-notifications {
  background-image: url(../img/icons/indicator-notifications.png);
}

.open>a>.icon-indicator-notifications {
  background-image: url(../img/icons/indicator-notifications-active.png);
}

.icon-session-avatar {
  background-image: url(../img/icons/session-avatar.png);
}

.open>a>.icon-session-avatar {
  background-image: url(../img/icons/session-avatar-active.png);
}

.icon-session-profile {
  background-image: url(../img/icons/session-profile.png);
}

.icon-session-logout {
  background-image: url(../img/icons/session-logout.png);
}

.icon-close {
  background-image: url(../img/icons/close.png);
}

.icon-search {
  background-image: url(../img/icons/filter-search.png);
}

.open>button>.icon-search {
  background-image: url(../img/icons/filter-search-active.png);
}

.icon-back {
  background-image: url(../img/icons/back.png);
}

.icon-lists-show-main {
  background-image: url(../img/icons/lists-show-main.png);
}

.icon-lists-show-quality {
  background-image: url(../img/icons/lists-show-quality.png);
}

.icon-plan-sendings {
  background-image: url(../img/icons/plan-sendings-icon.svg);
}

.icon-plan-contacts {
  background-image: url(../img/icons/plan-contacts-icon.svg);
}

.icon-plan-active {
  background-image: url(../img/icons/plan-active.svg);
}

.icon-plan-disabled {
  background-image: url(../img/icons/plan-disabled.svg);
}

.icon-plan-to-monthly {
  background-image: url(../img/icons/plan-to-monthly.svg);
}

.icon-plan-invoice-empty {
  background-image: url(../img/icons/plan-invoice-empty.svg);
}

.icon-plan-transfer {
  background-image: url(../img/icons/plan-transfer.svg);
}

.icon-plan-creditcard {
  background-image: url(../img/icons/plan-creditcard.svg);
}

.icon-plan-confirmation-monthly {
  background-image: url(../img/icons/plan-confirmation-monthly.svg);
}

.icon-plan-confirmation-monthly-contacts {
  background-image: url(../img/icons/plan-confirmation-monthly-contacts.svg);
}

.icon-plan-confirmation-monthly-sendings {
  background-image: url(../img/icons/plan-confirmation-monthly-sendings.svg);
}

.icon-plan-confirmation-pack {
  background-image: url(../img/icons/plan-confirmation-emails.svg);
}

.icon-plan-confirmation-pack-contacts {
  background-image: url(../img/icons/plan-confirmation-emails-contacts.svg);
}

.icon-plan-confirmation-pack-sendings {
  background-image: url(../img/icons/plan-confirmation-emails-sendings.svg);
}

.icon-plan-payment-success {
  background-image: url(../img/icons/plan-payment-success.svg);
  width: 150px;
  height: 158px;
}

.icon-product-custom {
  background-image: url(../img/icons/product-custom.svg);
}

.icon-location {
  background-image: url(../img/icons/location.svg);
}

.icon-role-admin {
  background-image: url(../img/icons/role-admin.svg);
}

.disabled .icon-role-admin-profile {
  background-image: url(../img/icons/role-admin-profile.svg);
}

.icon-role-admin-profile {
  background-image: url(../img/icons/role-admin-profile.svg);
}

.icon-role-admin-disabled {
  background-image: url(../img/icons/role-admin-disabled.svg);
}

.active .icon-role-admin {
  background-image: url(../img/icons/role-admin-active.svg);
}

.icon-role-admin-card {
  background-image: url(../img/icons/role-admin-card.svg);
}

.icon-role-author {
  background-image: url(../img/icons/role-author.svg);
}

.disabled .icon-role-author-profile {
  background-image: url(../img/icons/role-author-profile.svg);
}

.icon-role-author-profile {
  background-image: url(../img/icons/role-author-profile.svg);
}

.icon-role-author-disabled {
  background-image: url(../img/icons/role-author-disabled.svg);
}

.active .icon-role-author {
  background-image: url(../img/icons/role-author-active.svg);
}

.icon-role-author-card {
  background-image: url(../img/icons/role-author-card.svg);
}

.icon-role-custom {
  background-image: url(../img/icons/role-custom.svg);
}

.disabled .icon-role-custom-profile {
  background-image: url(../img/icons/role-custom-profile.svg);
}

.icon-role-custom-profile {
  background-image: url(../img/icons/role-custom-profile.svg);
}

.icon-role-custom-disabled {
  background-image: url(../img/icons/role-custom-disabled.svg);
}

.active .icon-role-custom {
  background-image: url(../img/icons/role-custom-active.svg);
}

.icon-role-custom-card {
  background-image: url(../img/icons/role-custom-card.svg);
}

.icon-role-manager {
  background-image: url(../img/icons/role-manager.svg);
}

.disabled .icon-role-manager-profile {
  background-image: url(../img/icons/role-manager-profile.svg);
}

.icon-role-manager-profile {
  background-image: url(../img/icons/role-manager-profile.svg);
}

.icon-role-manager-disabled {
  background-image: url(../img/icons/role-manager-disabled.svg);
}

.active .icon-role-manager {
  background-image: url(../img/icons/role-manager-active.svg);
}

.icon-role-manager-card {
  background-image: url(../img/icons/role-manager-card.svg);
}

.icon-role-viewer {
  background-image: url(../img/icons/role-viewer.svg);
}

.disabled .icon-role-viewer-profile {
  background-image: url(../img/icons/role-viewer-profile.svg);
}

.icon-role-viewer-profile {
  background-image: url(../img/icons/role-viewer-profile.svg);
}

.icon-role-viewer-disabled {
  background-image: url(../img/icons/role-viewer-disabled.svg);
}

.active .icon-role-viewer {
  background-image: url(../img/icons/role-viewer-active.svg);
}

.icon-role-viewer-card {
  background-image: url(../img/icons/role-viewer-card.svg);
}

.icon-location {
  background-image: url(../img/icons/location.svg);
}

.icon-user-email {
  background-image: url(../img/icons/mail.svg);
}

/* Sidebar icons animation */

.icon-normal {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.icon-active {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.active>a .icon-active {
  opacity: 1;
}

.active>a .icon-normal {
  opacity: 0;
}

.menu>li {
  border-left: 3px solid transparent;
}

.menu>li.open {
  border-left: 3px solid #17ADE4;
}

.menu>li {
  border-bottom: 1px solid #dddddd;
}































/*

Refactoring

*******************************************/

h4 {
  font-size: 16px;
}

.label {
  border-radius: 30px;
  font-weight: normal;
  margin-left: .2rem;
}

iframe {
  border: none;
}

textarea {
  resize: none;
  min-height: 60px;
}

/* Forms */

input:focus {
  outline: none;
  box-shadow: none;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 1px 0 0;
  vertical-align: text-top;
}

.form-group>.checkbox {
  /*    margin-top: 0;*/
}

input.disabled {
  opacity: 0.5;
}

.error-message {
  color: #a94442;
}

.prompt {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  height: 100%;
}

.prompt-content {
  border: 1px solid #ddd;
  background: #FFF;
  margin: 250px 60px 0;
  padding: 40px 50px;
  border-radius: 8px;
}

.prompt-footer {
  text-align: right;
}

.prompt-content p {
  padding: 0;
  color: #696969;
}

.prompt-content .prompt-title {
  font-size: 1.5rem;
  font-weight: 400;
  color: #969696;
  margin-bottom: 16px;
}

/* Labels */

.label-info {
  background-color: #428bca;
}

/* Alerts */

.alert {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  padding: 22px;
  line-height: 1;
}

.alert-danger a {
  color: #A94442;
}

.alert-danger a:hover {
  color: #722D2C;
}

.progress {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.6);
  box-shadow: none;
}

.filename {
  margin: 10px 0;
  display: block;
}

/* Dropdowns */

.dropdown-menu>li>p {
  color: #999;
  padding: 5px 10px 0;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  background: #DDD;
  color: #333;
}

/* Media Object */

.media-body a {
  display: block;
}

/* Panels */

.panel-default>.panel-heading {
  background-color: #EBEBEB;
}

.panel-heading button.pull-right {
  margin-top: -5px;
}

/*

Additional Style

*******************************************/

/* Loading */

.loading {
  display: inline-block;
  opacity: 0;
  margin: 10px 0 10px 10px;
  padding: 0 0 0 24px;
  background-image: url(../img/loading.gif);
  background-repeat: no-repeat;
  background-position: center left;
}

.loading.off {
  display: none;
}

.loading-box,
.loading-box:before,
.loading-box:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loading-box {
  color: #c6edfa;
  font-size: 10px;
  margin: 40px auto;
  margin-top: 0px;
  margin-bottom: 60px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loading-box:before,
.loading-box:after {
  content: '';
  position: absolute;
  top: 0;
}

.loading-box:before {
  left: -3em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loading-box:after {
  left: 3em;
}

@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 1.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 1.5em 0 0;
  }
}



/* Titles */

.section-title h1 {
  font-size: 26px;
  margin-bottom: 15px;
  margin-top: 10px;
}

/* Links */

.actionable {
  color: #337ab7;
}

.actionable:hover {
  color: #235276;
}

/* Rating */

.stars {
  height: 16px;
  width: 50px;
  background-image: url(../img/stars.svg);
  background-repeat: no-repeat;
}

.stars.rating-0 {
  background-position: 0px 0px;
}

.stars.rating-5,
.stars.rating-10,
.stars.rating-15,
.stars.rating-20,
.stars.rating-25,
.stars.rating-30 {
  background-position: -188px 0px;
}

.stars.rating-35,
.stars.rating-40,
.stars.rating-45,
.stars.rating-50,
.stars.rating-55,
.stars.rating-60,
.stars.rating-65 {
  background-position: -375px 0px;
}


.stars.rating-70,
.stars.rating-75,
.stars.rating-80,
.stars.rating-85,
.stars.rating-90,
.stars.rating-95,
.stars.rating-100 {
  background-position: -936px 0px;
}

.stars.rating--100 {
  filter: grayscale(100%);
}

.lists.section .stars,
.contacts.section .stars {
  margin-top: 9px;
}

.contacts.section .list .stars {
  margin-left: 25px;
}

.list-checker input[type="checkbox"],
.list-checker input[type="radio"] {
  float: left;
}

.list-checker .list-item {
  margin-left: 30px;
}

.selector-all {
  display: block;
  margin-bottom: 20px;
  font-size: 0.875rem;
}

/* Grid */

ul.grid {
  padding: 0;
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding-left: 40px;
  padding-top: 5px;
  display: block;
}

ul.grid:last-child {
  margin-bottom: 40px;
}

ul.grid li.big {
  width: 33%;
}

ul.grid li.big .thumbnail.folder .caption {
  padding: 15px 0;
}

.modal-dialog.big {
  max-width: 800px;
  width: 90%;
}

ul.grid li.medium {
  width: 33%;
}

ul.grid li {
  display: block;
  width: 25%;
  margin: 0;
  padding: 0;
  float: left;
}

ul.grid li.divider {
  display: block;
  width: 100%;
}

/* Preview */

.preview {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
}

.preview .loading {
  background-color: #fff;
  background-image: url("../img/loading.gif");
  background-position: 80px 50%;
  bottom: -10px;
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  margin: 0;
}

.preview .loading.show {
  display: block;
}

/* Overlay */

.overlay {
  background-color: rgba(26, 30, 36, .8);
  position: relative;
  opacity: 0;
  text-align: center;
  /*-webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;*/
}

/* Thumbnails */

.thumbnail {
  padding: 0;
  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.1); */
  position: relative;
  margin-top: 0;
  margin-bottom: 30px;
  margin-right: 40px;
  overflow: hidden;
  transition: all 0.2s ease;
  border-radius: 9px;
}

.thumbnail:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.thumbnail .overlay {
  margin-top: -145px;
  height: 145px;
  max-height: 145px;
  color: #FFF;
  /* cursor: pointer; */
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  transition: all 0.1s ease;
}

.selected .thumbnail .overlay,
.thumbnail:hover .overlay {
  opacity: 1;
}

.thumbnail .overlay i {
  line-height: 260px;
  font-size: 70px;
}

.thumbnail .overlay .btn {
  margin-top: 45px;
}

.thumbnail .checker {
  left: 12px;
  top: 11px;
  position: absolute;
  display: none;
}

.thumbnail.folder>.checker {
  top: 15px;
}

.thumbnail .checker input[type=checkbox] {
  cursor: pointer;
  display: none;
}

/*
.thumbnail .checker input[type=checkbox]{
  display: none;
}
*/

.thumbnail .checker input[type=checkbox]+label:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 8px;
  width: 23px;
  font-size: 1.4em;
  color: #999;
}

.thumbnail .checker input[type=checkbox]:not(:checked)+label:before {
  content: "\f096";
  /* Checkbox Unchecked */
}

.thumbnail .checker input[type=checkbox]:checked+label:before {
  content: "\f14a";
  /* Checkbox Checked */
  color: #00afe7;
}

.thumbnail .checker label,
.overlay .status label {
  cursor: pointer;
}

.selected .thumbnail .checker,
.thumbnail:hover .checker {
  display: block;
}

.images .thumbnail .preview {
  height: 145px;
  max-height: 145px;
  background-position: center;
  display: block;
  background-size: contain;
}

.designs .thumbnail .preview {
  height: 145px;
  max-height: 145px;
  background-position: top;
  display: block;
}


.thumbnail .count {
  background: #FFF;
  border-radius: 50px;
  color: #40b1d1;
  display: inline-block;
  font-size: 14px;
  left: 20px;
  margin-top: -26px;
  padding: 5px 7px;
  position: absolute;
  background-repeat: no-repeat;
}

.thumbnail .caption {
  padding: 15px 20px;
}

.thumbnail h3 {
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.thumbnail h3 a {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.thumbnail p {
  color: #aaa;
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 0;
}

.thumbnail.image .caption {
  border-top: 1px solid #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.designs.section .thumbnail.image .preview {
  cursor: pointer;
}

.thumbnail.folder .caption {
  padding: 15px 45px;
  border-radius: 4px;
}

.thumbnail.folder h3 {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}

.selected .thumbnail .caption {
  background-color: #F3FBFE;
}

/*

Components and Plugins

****************************************************************/

/* Tiny MCE */

.mce-container[aria-label="HTML source code"] {
  z-index: 99999 !important;
}

/* Sortable */

.sortable {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sortable>li>.graphic.checkbox>i {
  display: inline-block;
  color: #696969;
  margin-right: 5px;
}

/* Tags */

.bootstrap-tagsinput {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: none;
  display: inline-block;
  padding: 8px 10px;
  color: #555;
  vertical-align: middle;
  border-radius: 6px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
}

.bootstrap-tagsinput input {
  width: 300px;
  border-radius: 5px;
  font-size: 0.875rem;
}

.bootstrap-tagsinput .tag {
  margin-right: 2px;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
}

.twitter-typeahead .tt-hint {
  display: none;
}

.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  display: none;
}

.tt-menu.tt-open {
  display: inline-block;
}

.tt-suggestion {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: nowrap;
}

.tt-suggestion:hover,
.tt-suggestion:focus,
.tt-suggestion.tt-cursor {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #00aee8;
}

.tt-selectable {
  cursor: pointer;
}

.tt-input {
  color: #333;
  border: none;
  box-shadow: none;
  padding: 0 0 0 0.5rem;
}

.tt-input:focus {
  box-shadow: none;
}

/* Code Mirror */

.CodeMirror {
  border: 1px solid #DDD;
}

.CodeMirror-cursor {
  min-height: 20px;
}

.CodeMirror pre.CodeMirror-placeholder {
  color: #999;
}

/*

Sections

****************************************************************/

/* Maintenance */

.maintenance {
  text-align: center;
}

.maintenance p {
  font-size: 18px;
  padding: 20px;
  color: #696969;
}

.maintenance svg {
  display: inline;
}

/* Login */

.login {
  overflow: auto;
}

.login.account-details-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  height: 100%;
  background-image: url(../img/login-background.jpg);
}

.login .login-panel {
  background: #FFF;
}

.login .login-panel.marketing-details,
.login .login-panel.account-details {
  max-width: 640px;
}

.login .login-panel .login-panel-header {
  text-align: center;
  padding-top: 26px;
}

.login .login-panel .login-panel-header h1 {
  font-size: 2.25rem;
  color: #71D1F1;
  font-weight: 600;
  margin-bottom: 1rem;
}

.login .login-panel .login-panel-header h1.error-title {
  color: #a94442;
}

.circle-logo {
  background-image: url(../img/login-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: #FFF;
  height: 60px;
  width: 60px;
  margin-top: 0 auto;
  display: inline-block;
  margin-bottom: -34px;
  border-radius: 50px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.login .login-panel .login-panel-body,
.login .login-panel .login-panel-footer {
  background: #f5f5f5;
  padding: 0 30px;
  font-size: 0.875rem;
}

.login .login-panel .login-panel-body {
  padding-top: 48px;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
}

.login.account-details-modal .login-panel-body {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.login .login-panel.marketing-details .login-panel-body,
.login .login-panel.account-details .login-panel-body,
.login .login-panel.marketing-details .login-panel-footer,
.login .login-panel.account-details .login-panel-footer {
  background-color: #FFF;
}

.login .login-panel.account-details .login-panel-body .account-details-header {
  /*    padding-left: 170px;
    background-image: url(../img/icons/account-details.svg);
    background-repeat: no-repeat;
    padding-top: 16px;*/
  margin-bottom: 30px;
}

.login .login-panel.account-details .login-panel-body .account-details-header h1 {
  font-size: 1.875rem;
  color: #71D1F1;
  font-weight: 600;
  margin-top: 10px;
}

.login .login-panel.account-details .login-panel-body .account-details-header p,
.login .login-panel.account-details .login-panel-body p.user-agreement {
  color: #696969;
  font-size: 0.875rem;
}

.login .login-panel .login-panel-body .user-agreement {
  color: #696969;
  margin-top: 1.5rem;
}

.login .login-panel.marketing-details .login-panel-body .marketing-details-header {
  margin-bottom: 30px;
}

.login .login-panel.marketing-details .login-panel-body .marketing-details-header h1 {
  font-size: 1.875rem;
  color: #71D1F1;
  font-weight: 600;
  margin-top: 10px;
}

.login .login-panel.marketing-details .login-panel-body .marketing-details-header p {
  color: #696969;
  font-size: 0.875rem;
}

.login .login-panel .login-panel-body h2 {
  font-size: 18px;
  color: #696969;
  text-align: center;
  margin: 0 auto 10px;
  line-height: 1.35;
}

.login .login-panel .login-panel-body h2.narrow-subtitle {
  max-width: 60%;
}

.login .login-panel .login-panel-body h2.error-message {
  margin-bottom: 20px;
}

.login .login-panel .login-panel-body .form-group {
  margin-bottom: 1.5rem;
}

.login .login-panel .login-panel-body .form-group-last {
  margin-bottom: 0;
}

.login .login-panel .login-panel-body .form-control {
  background-color: #fdfdfd;
  background-repeat: no-repeat;
  height: 44px;
  font-size: 0.875rem;
}

.login .login-panel .login-panel-body .user-input {
  padding-left: 42px;
  background-image: url(../img/icons/login-user.svg);
  background-position: 5px center;
}

.login .login-panel .login-panel-body .user-name-input {
  padding-left: 42px;
  background-image: url(../img/icons/signup-user-name.svg);
  background-position: 5px center;
}

.login .login-panel .login-panel-body .account-name-input {
  padding-left: 42px;
  background-image: url(../img/icons/signup-account-name.svg);
  background-position: 7px center;
}

.login .login-panel .login-panel-body .password-input {
  padding-left: 42px;
  background-image: url(../img/icons/login-pass.svg);
  background-position: 7px center;
}

.login .login-panel .login-panel-body .input-group-addon {
  font-size: 16px;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.login .login-panel .login-panel-body .input-group .fa-eye {
  opacity: 0.7;
}

.login .login-panel .login-panel-body .input-group .fa-eye-slash {
  display: none;
}

.login .login-panel .login-panel-body .input-group.revealed .fa-eye {
  display: none;
}

.login .login-panel .login-panel-body .input-group.revealed .fa-eye-slash {
  display: inline-block;
}

.login .login-panel .login-panel-body .password-recovery-link {
  font-size: 0.875rem;
  display: inline-block;
  margin-top: 10px;
}

.login .login-panel .login-panel-body .login-error {
  color: #FF5572;
  border-left: 2px solid #FF5572;
  padding-left: 10px;
  margin-top: 20px;
}

.login .login-panel .login-panel-body .login-error p {
  margin: 0;
}

.login .login-panel .login-panel-body .login-error a {
  color: #FF5572;
  font-weight: bold;
}

.login .login-panel .login-panel-body .illustration {
  width: 80%;
  margin: 50px auto 60px;
  display: block;
}

.login .login-panel .login-panel-footer {
  padding-top: 28px;
  padding-bottom: 36px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  text-align: right;
  margin-bottom: 30px;
}

.login .login-panel .login-panel-footer .btn {
  width: 100%;
  margin-bottom: 20px;
}

.login .login-panel .login-panel-footer .btn-outline {
  background-color: transparent;
}

.login .login-help {
  font-size: 0.875rem;
  display: block;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 36px;
  color: #FFF;
}

.login .login-help a:hover {
  color: #FFF;
}

.login .login-panel .login-panel-body h3 {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  color: #969696;
  margin: 0 auto 20px;
  line-height: 1.3;
}

.login .login-panel .login-panel-body h3.recover-message {
  margin: 20px auto 20px;
}

.login .iti {
  display: block;
}

/* Not Found */

.not-found {
  padding-top: 150px;
  text-align: center;
}

/* Opt-In */

.browser-bar {
  background: url(../img/browser.png) no-repeat 15px 15px;
  min-height: 40px;
}

.live-preview {
  background: url(../img/transparent.png);
  pointer-events: none;
}

.email-preview .email-preview-wrapper {
  background: #f1f1f1;
  padding: 15px;
  color: #575756;
  border-top: 1px solid #DDD;
  pointer-events: none;
}

.email-preview .dl-horizontal {
  margin-top: 20px;
}

.email-preview .email-preview-main {
  border: 1px solid #c6c6c5;
  background: #FFF;
  padding: 40px;
}

.email-preview .email-preview-main .dl-horizontal dt {
  font-weight: normal;
  text-align: left;
  width: 100px;
}

.dl-horizontal.long dt {
  width: 300px;
  margin-right: 20px;
}

.email-preview .email-preview-main .dl-horizontal dd {
  margin-left: 110px;
}

.email-preview .email-preview-title {
  font-size: 28px;
  font-weight: 600;
}

.email-preview .email-preview-header {
  font-size: 20px;
}

.email-preview .email-preview-button {
  background: #336699;
  display: inline-block;
  padding: 10px 20px;
  color: #FFF;
  font-weight: bold;
  margin-top: 25px;
}

.email-preview .email-preview-footer {
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 25px;
}

textarea.embed-code {
  font-family: monospace;
  font-size: 10px;
  height: 120px;
  min-height: auto;
  padding-bottom: 0;
}

/* Charts */

/*
.pie-chart canvas {
    width: 100%;
    height: auto;
    margin: 0 auto;
}
*/

/* List Actions */

.optional {
  /*    padding-left: 30px;*/
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.form-control.input-mini {
  display: inline-block;
  padding: 0;
  text-align: right;
  width: 80px;
  margin: 0 5px;
}

.optional .form-control.select-mini {
  display: inline-block;
  font-size: 12px;
  height: 24px;
  padding: 0 5px;
  width: auto;
  margin: 0 5px;
}

.optional.disabled {
  color: #888;
  display: none;
}

.dropzone {
  border: 3px dashed #DDD;
  border-radius: 5px;
  padding: 40px 0;
  text-align: center;
}

.dropzone.active {
  border-color: #0088cc;
}

/* Templates */

.preview iframe {
  height: 0;
  width: 100%;
}

/* Explorer */

ul.explorer {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

ul.explorer li {
  display: block;
  width: 16.666%;
  margin: 0 0 20px;
  padding: 0;
  float: left;
  text-align: center;
  cursor: pointer;
}

ul.explorer li.folder .image {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/folder.png);
  min-height: 42px;
}

ul.explorer li.file .image {
  background-repeat: no-repeat;
  background-position: center top;
  min-height: 120px;
}

ul.explorer li.file.cleared {
  clear: left;
}

ul.explorer li .name {
  font-weight: bold;
  margin: 10px 0;
}

ul.explorer li:hover .name {
  color: #0088cc;
}

.fa-ul {
  margin-bottom: 0;
}

.fa-ul>li {
  margin-bottom: 5px;
  position: relative;
}

.upload-preview-item {
  margin: 10px 0;
  border-bottom: 1px solid #DDD;
  padding: 10px 0;
}

.upload-preview-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.upload-preview-item .name {
  font-weight: bold;
  margin-bottom: 5px;
}

.upload-preview-item .progress-bar {
  float: none;
}

/* Toggler */

.reveal {
  display: none;
  margin: 0 0 20px;
  padding: 20px;
  border: 1px solid #DDD;
  border-radius: 4px;
}

.reveal.in {
  display: block;
}


/*************** SCROLLBAR BASE CSS ***************/

.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
}

.scroll-wrapper>.scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important;
}

.scroll-wrapper>.scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.scroll-element {
  display: none;
}

.scroll-element,
.scroll-element div {
  box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default;
}

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999;
}

.scroll-textarea>.scroll-content {
  overflow: hidden !important;
}

.scroll-textarea>.scroll-content>textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important;
}

.scroll-textarea>.scroll-content>textarea::-webkit-scrollbar {
  height: 0;
  width: 0;
}





/**************** datetime picker ****************/
/*!
 * Datetimepicker for Bootstrap 3
 * version : 4.15.35
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget {
  list-style: none;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  margin: 2px 0;
  padding: 4px;
  width: 19em;
}

@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}

@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}

@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}

.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute;
}

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #cccccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #cccccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}

.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}

.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0;
}

.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}

.bootstrap-datetimepicker-widget .timepicker-picker {
  margin-top: 30px;
}

.bootstrap-datetimepicker-widget .timepicker-picker .table-condensed a {
  font-size: 18px;
}

.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: lighter;
  font-size: 40px;
  margin: 0;
}

.bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px;
}

.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Hours";
}

.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Minutes";
}

.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle AM/PM";
}

.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Clear the picker";
}

.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Set the date to today";
}

.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
}

.bootstrap-datetimepicker-widget .picker-switch::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle Date and Time Screens";
}

.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}

.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
}

.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}

.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  text-align: center;
  border-radius: 4px;
}

.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}

.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget table th.prev::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Previous Month";
}

.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Next Month";
}

.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #eeeeee;
}

.bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px;
}

.bootstrap-datetimepicker-widget table td.cw {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #777777;
}

.bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px;
  border-radius: 50px;
}

.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  background: #eeeeee;
  cursor: pointer;
}

.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #777777;
}

.bootstrap-datetimepicker-widget table td.today {
  position: relative;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #337ab7;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff;
}

.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 4px;
}

.bootstrap-datetimepicker-widget table td span:hover {
  background: #eeeeee;
}

.bootstrap-datetimepicker-widget table td span.active {
  background-color: #337ab7;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.bootstrap-datetimepicker-widget table td span.old {
  color: #777777;
}

.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed;
}

.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}

.bootstrap-datetimepicker-widget.wider {
  width: 21em;
}

.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 1.8em !important;
}

.input-group.date .input-group-addon {
  cursor: pointer;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/************* Campaign - Summary **************/
.error-title {
  color: #FB5830;
}

#summary-tab .validate h2 {
  color: #9EC967;
}

#summary-tab .validate .message {
  display: block;
}

#summary-tab .message {
  color: #C5C5C5;
}

#summary-tab .warnings {
  color: #FCD84B;
}

#summary-tab .errors {
  color: #FC5931;
}

#summary-tab .has-error h2 {
  color: #FB5830;
}




/******************************************************************

Automation

******************************************************************/

.automations.section .list ul.table>div>div.check-cell>.iconic {
  background-image: url(../img/icons/lists/item-automation.svg);
}

.automations.section .list ul.table>div>div.check-cell>.iconic {
  cursor: pointer;
}

.automations.section .list .list-tags a {
  cursor: default;
}

.automations div.steps {
  /* max-width: 600px; */
  margin-top: 3rem;
  /* background-color: #fdfdfd; */
}

.automations .steps h4 {
  margin-top: 0px;
  margin-bottom: 15px;
  color: #00aee8;
}

.automations .steps h4 i.fa {
  margin-right: 1rem;
}

.automations .steps .step {
  border: 1px solid #ddd;
  border-radius: 9px;
  margin: 1rem 0;
  padding: 2rem 2rem;
  text-align: center;
  position: relative;
  font-size: 0.875rem;
}

.automations .steps .step.clickeable {
  cursor: pointer;
}

.automations .steps .step.clickeable:hover {
  background-color: #fbfbfb;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  border-color: #00aee8;
}

.automations .steps .step.clickeable:hover h4 {
  color: #0b83b7;
}

.automations .steps .step.pending-box {
  border: 1px solid #FFECB5!important;
  background: #FFF9EB;
}

.automations .steps .step.pending-box:hover {
  background-color: #FFFCF3!important;
  border-color: #FFECB5!important;
}

.automations .steps .arrow {
  text-align: center;
  color: #444;
}

.automations .steps .step .edit-buttons {
  /* display: none; */
  display: block;
  color: #aaa;
  font-size: 14px;
  margin-left: 1rem;
  overflow: hidden;
  position: absolute;
  right: 2rem;
  top: 2rem;
}

/*
.automations .steps .step.clickeable .edit-buttons {
    display: block;
    color: #aaa;
    font-size: 14px;
    margin-left: 1rem;
    overflow: hidden;
    position: absolute;
    right: 2rem;
    top: 2rem;
} */

.automations .steps .step .edit-buttons .edit {
  display: none;
}

.automations .steps .step.clickeable .edit-buttons .edit {
  display: inline;
}


.automations .steps .step .edit-js {
  cursor: pointer;
}

.automations .steps .step.clickeable:hover .edit {
  display: inline;
  color: #888;
}

.automations .steps code {
  color: #03aee9;
  background-color: #f0fbff;
}

.automations .steps .step-legend {
  margin: 0;
  color: #696969;
}

.automations .steps .trigger-options {
  margin: 10px 0 0 0;
  color: #0b83b7;
}

.automations .steps .step.email div.preview {
  /* text-align: left; */
  max-width: 500px;
  margin: 1rem auto 0;
}

.automations .steps .step.email img.thumb {
  width: 100%;
  margin-top: 2rem;
  border: 2px solid #eee;
  padding: 5px;
  display: none;
}

.automations.metrics .period-selector {
  float: right;
  margin: 10px 20px;
  font-size: .85rem;
  white-space: nowrap;
}

.automations.metrics .period-selector ul {
  list-style: none;
  padding-left: 0;
}

.automations.metrics .period-selector .selected {
  font-weight: bold;
}

.automations.metrics .panel.activity .filtering li a {
  min-height: 100px;
  padding-top: 28px;
}

.automations.metrics .panel.activity .filtering li .hover-metric,
.automations.metrics .panel.activity .filtering li:hover .nohover-metric {
  display: none;
}

.automations.metrics .panel.activity .filtering li:hover .hover-metric,
.automations.metrics .panel.activity .filtering li .nohover-metric {
  display: block;
}

.edit-inline {
  outline: none;
  background: transparent;
  border: none;
  padding: 0;
  max-width: 85%;
  line-height: 1.25;
  white-space: nowrap;
  cursor: text;
}

span.edit-inline:hover {
  color: #fff;
  border-bottom: 1px dashed #888;
}

.edit-inline:focus {
  color: #fff;
}

small span.edit-inline:hover {
  color: #ccc;
  border-bottom: 1px dashed #888;
}

small .edit-inline:focus {
  color: #ccc;
}

span.edit-inline.empty {
  opacity: 0.5;
}

.create-automation .collection-pane ul {
  padding-left: 0;
  list-style: none;
  margin-top: 2rem;
}

.create-automation .collection-pane li {
  border: 1px solid #ddd;
  margin: 1rem 0;
  border-radius: 5px;
  padding: 1.5rem 2rem;
  cursor: pointer;
}

.create-automation .collection-pane li:hover {
  background-color: #fbfbfb;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  border-color: #00aee8;
}

.create-automation .collection-pane h4 {
  margin-top: 0px;
  margin-bottom: 1rem;
  color: #00aee8;
}

.create-automation .collection-pane li p.comments {
  margin-bottom: 0;
}

.create-automation .nav-pills>li>a {
  border-radius: 20px;
}

.create-automation h4 i {
  float: right;
}

.create-automation .collection-pane .disabled {
  cursor: default;
  background-color: #f0f0f0;
}

.create-automation .collection-pane li.disabled:hover {
  box-shadow: none;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
}

.create-automation li[data-type="simple"] {
  background-image: url(../img/campaign-simple.svg);
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 100px;
  padding-left: 125px;
}

.create-automation li[data-type="splitab"] {
  background-image: url(../img/campaign-splitab.svg);
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 100px;
  padding-left: 125px;
}

.create-automation li[data-type="smart"] {
  background-image: url(../img/campaign-smart.svg);
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 100px;
  padding-left: 125px;
}

span.beta-batch {
  background-color: #42aee8;
  color: #fff;
  font-size: 0.75rem;
  border-radius: 12px;
  padding: 2px 8px;
  font-weight: bold;
  letter-spacing: 0.05rem;
  -webkit-font-smoothing: antialiased;
  margin-left: 0.5rem;
}

.modal-dialog .advanced-options {
  font-size: 1rem;
  cursor: pointer;
  color: #00aee8;
  margin: 1.5rem 0;
}

.activity .info-cell .icon-button {
  color: #777;
}
.activity .info-cell .icon-button:hover {
  color: #337ab7;
}

/******************************************************************

Responsive Styles

******************************************************************/

/* Tablet */

@media (min-width: 768px) {

  .btn-link {
    white-space: nowrap;
  }

  .mobile-splash {
    display: none;
  }

  html.has-pattern {
    background-image: url(../img/login-background.jpg);
  }

  .login .login-panel {
    margin: 40px auto 0;
    border-radius: 9px;
    max-width: 520px;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  }

  .login .login-help a {
    color: #F2F2F2;
  }

  .login .login-panel .login-panel-body,
  .login .login-panel .login-panel-footer {
    padding: 0 70px;
  }

  .login .login-panel .login-panel-body {
    padding-top: 48px;
  }

  .login .login-panel .login-panel-footer .btn {
    margin-left: 18px;
    width: auto;
    margin-bottom: 0;
  }

  .login .login-panel .login-panel-footer {
    padding-top: 28px;
    padding-bottom: 36px;
    min-height: 100px;
  }

  .login .login-panel .login-panel-footer button:first-child {
    float: right;
  }
}

/* Desktop */

@media (min-width: 992px) {

  /* Off Canvas Sidebar */

  .app .sidebar {
    left: 0px;
  }

  .app .main {
    left: 210px;
  }

  .app .header .left-menu .hamburguer {
    display: none;
  }

  /* Tabs */

  .nav-tabs li a {
    padding: 15px 20px;
  }
}