// Colors
$green: #27AE60;
$darkGreen: #219653;
$lightGreen: #6FCF97;

$blue: #2D9CDB;
$darkBlue: #2F80ED;
$lightBlue: #56CCF2;

$red: #EB5757;
$orange: #F2994A;
$yellow: #F2C94C;

$purple: #9B51E0;
$lightPurple: #BB6BD9;

$gray1: #333333;
$gray2: #4F4F4F;
$gray3: #828282;
$gray4: #BDBDBD;
$gray5: #E0E0E0;
$gray6: #F2F2F2;