@tailwind base;

input {
    @apply placeholder-gray-400
}

@tailwind components;
@tailwind utilities;

@layer base {
    .tw-hidden {
      @apply hidden;
    }
  }

.Canny_BadgeContainer .Canny_Badge {
    position: absolute;
    top: 20px;
    right: 175px;
    border-radius: 10px;
    background-color: #40ace3;
    padding: 3px;
    border: 2px solid #c6e9f5;
    visibility: visible;
    cursor: pointer;
    @apply animate-ping;
}

.newLayout .Canny_BadgeContainer .Canny_Badge {
  position: absolute;
  top: 10px;
  right: 20px;
  border-radius: 10px;
  background-color: #40ace3;
  padding: 3px;
  border: 2px solid #c6e9f5;
  visibility: visible;
  cursor: pointer;
  @apply animate-ping;
}

iframe#canny-changelog-iframe {
    left: 40px !important;
}

.transparent-background-img {
    background: url(../img/transparent.png);
  }

.tippy-box[data-theme='light'] {
  @apply ring-1 ring-black ring-opacity-5 shadow rounded-lg text-base
}
.tippy-box[data-theme='light'] .tippy-content {
  background-color: #FAFAFA;
  color: #737373;
  font-weight: 600;
  @apply  shadow text-base
}
.tippy-box[data-theme='light'] > .tippy-content {
  padding: 6px 16px;
  @apply rounded-md
}

.tippy-content {
  overflow-wrap: break-word;
  font-weight: 500 !important;
}