
.eddie {
    background: #DDD;
}

.eddie .dashboard {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #DDD;
    z-index: 1040;
}

.eddie .move {
    top: 3rem;
}

/* Utils */

.eddie a {
    outline: none;
    outline-offset: 0;
    cursor: pointer;
}

.eddie a:hover {
    text-decoration: none;
}

.eddie .btn.active.focus,
.eddie .btn.active:focus,
.eddie .btn.focus,
.eddie .btn.focus:active,
.eddie .btn:active:focus,
.eddie .btn:focus {
    outline: none;
    box-shadow:none;
}

.eddie label {
    background: #FFF;
}

.eddie .dropzone {
    padding: 80px 0;
}

.eddie [disabled] {
    opacity: 0.25;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    cursor: default !important;
}

.eddie label.selector input[type=radio] {
    display: none;
}

.eddie label.selector {
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 40px;
    padding-bottom: 10px;
    text-align: center;
    width: 32.5%;
}

.eddie label.selector.selected {
    border: 3px solid #0088cc;
}

.eddie label.selector i {
    color: #aaa;
    display: inline-block;
    font-size: 121px;
    margin-top: 10px;
}

.eddie label.selector span {
    display: block;
}

/* Pills */

.eddie .templates .nav-pills > li > a,
.eddie .regions .nav-pills > li > a {
    border-radius: 33px;
    margin-bottom: 10px;
}

/* Toolbars */

.eddie .btn {
    /* border-radius: 0; */
}

.eddie .btn-default {
    border-width: 1px;
    border-color: #DDD;
    /* border-radius: 0 !important; */
}

.eddie .btn-danger.btn-link {
    color: #d9534f;
}

.eddie .btn-group > .btn + .dropdown-toggle:active:focus {
    padding-right: 8px;
    padding-left: 8px;
}

.eddie .btn-default:focus {
    background-color: #FFF;
    border-color: #CCC;
    border-width: 1px;
}

.eddie .buttonset {
    padding: 10px;
    position: absolute;
    top: 0;
    z-index: 1030;
}

.eddie .buttonset.right {
    right: 0;
}

.eddie .main-toolbar {
    background: #FFF;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3);
    border-top: 1px solid #aaa;
    padding: 10px;
    position: relative;
    z-index: 1020;
    text-align: center;
    margin: 0;
    min-height: 60px;
}

.eddie .hidden {
    opacity: 0;
}

.eddie .main-deprecate {
    background: rgb(254 252 232);
    padding: 1rem;
    position: fixed;
    z-index: 1020;
    text-align: center;
    top: 0;
    margin: 0;
    min-height: 20px;
    width: 100%;
}

.eddie .main-deprecate .container {
    display: flex;
}

.eddie .main-deprecate p {
    font-size: 0.8rem;
    color: rgb(161 98 7);
}

.eddie .main-deprecate .article {
    color: rgb(161 98 7);
    font-weight: bold;
    text-decoration: underline;
}

.eddie .main-deprecate .close {
    color: rgb(161 98 7);
    font-weight: bold;
    position: absolute;
    right: 0;
    margin-right: 1rem;
    text-decoration: none;
}

.eddie .main-toolbar .dropdown-menu {
    text-align: left;
    max-height: 300px;
    overflow: auto;
}

.eddie .dropdown-menu > li > a {
    padding: 3px 13px;
}

.eddie .dropdown-menu i {
    margin-right: 5px;
}

.eddie .region-toolbar {
    position: absolute;
}

.tooltip.left .tooltip-arrow {
    right: 2px;
}

.eddie .add-region-button .dropdown-toggle {
    border-left: 1px solid white;
    padding-left: 8px;
    padding-right: 8px;
}

/* Fonts */

.eddie .main-toolbar .arial {
    font-family: "Arial", Helvetica, sans-serif;
}

.eddie .main-toolbar .arial-black {
    font-family: "Arial Black", Helvetica, sans-serif;
}

.eddie .main-toolbar .century {
    font-family: "Century Gothic", serif;
}

.eddie .main-toolbar .courier {
    font-family: "Courier", monospace;
}

.eddie .main-toolbar .courier-new {
    font-family: "Courier New", monospace;
}

.eddie .main-toolbar .geneva {
    font-family: "Geneva", sans-serif;
}

.eddie .main-toolbar .georgia {
    font-family: Georgia, serif;
}

.eddie .main-toolbar .helvetica {
    font-family: Helvetica, Arial, sans-serif;
}

.eddie .main-toolbar .lucida {
    font-family: "Lucida", sans-serif;
}

.eddie .main-toolbar .lucida-grande {
    font-family: "Lucida Grande", sans-serif;
}

.eddie .main-toolbar .lucida-sans {
    font-family: "Lucida Sans", sans-serif;
}

.eddie .main-toolbar .ms-serif {
    font-family: "MS Serif", sans-serif;
}

.eddie .main-toolbar .new-york {
    font-family: "New York", sans-serif;
}

.eddie .main-toolbar .palatino {
    font-family: "Palatino", sans-serif;
}

.eddie .main-toolbar .palatino-linotype {
    font-family: "Palatino Linotype", sans-serif;
}

.eddie .main-toolbar .tahoma {
    font-family: "Tahoma", Verdana, sans-serif;
}

.eddie .main-toolbar .times {
    font-family: "Times", sans-serif;
}

.eddie .main-toolbar .times-new-roman {
    font-family: "Times New Roman", sans-serif;
}

.eddie .main-toolbar .trebuchet {
    font-family: "Trebuchet MS", sans-serif;
}

.eddie .main-toolbar .verdana {
    font-family: "Verdana", Tahoma, sans-serif;
}

/* Wrapper and preview */

.eddie .wrapper {
    height: 85%;
    left: 0;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    background: #dddddd;
}

.eddie .email-preview {
    padding: 30px 200px;
    margin-bottom: 200px;
}

.eddie {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.eddie .eddie-page a {
    color: #0000EE;
}

.eddie .eddie-page .selected-element {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    -o-user-select: text;
    user-select: text;
}

.eddie .eddie-page .selected-image-container > img:not(.selected-element),
.eddie .eddie-page [data-ref]:hover {
    outline: 1px dotted #AAA;
    outline-offset: -1px;
}

.eddie [contenteditable]:active,
.eddie [contenteditable]:focus{
    border:none;
    outline:none;
}

.eddie .eddie-page sub {
    bottom: 0;
    font-size: inherit;
    line-height: inherit;
    position: inherit;
    vertical-align: inherit;
}

/* Featured Templates */

.eddie li:not(.active) > .featured {
    margin-bottom: 0;
    -webkit-animation:parpadeo 1.4s infinite;
}

@keyframes parpadeo {
    0% {color:#B7E8FF;}
    100% {color:#0088cc;}
}

.eddie a.featured::after, .eddie a.featured::before {
    content: "⭐";
    margin-left: 0.5rem;
    margin-right: 0.5rem;    
}

/* Selected states */

.eddie .eddie-page tr td .selected-element,
.eddie .eddie-page tr td .selected-element:hover {
    outline: 2px dotted #0088cc;
    outline-offset: -1px;
    background: transparent;
}

.eddie .eddie-page .selected-region {
    outline: 3px solid #0088cc;
}

.eddie .eddie-page .selected-image-container {
    display: inline;
}

/* Modals */

.eddie .modal {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -khtml-user-drag: element;
    -webkit-user-drag: element;
}

.eddie .modal a {
    color: #0088cc;
}

.eddie .modal-footer {
    margin-top: 0;
}

.eddie .modal .tabs {
    margin-bottom: 15px;
}

.eddie .modal p {
    margin-bottom: 15px;
    color: #999;
}

.eddie .modal p.error-message {
    color: #ff0000;
}

/* Margin modals */

.eddie .margin.modal .sample-box {
    border: 1px dashed #aaa;
    height: 200px;
    margin: 30px auto 50px;
    width: 200px;
}

.eddie .margin.modal .form-group {
    width: 120px;
    text-align: center;
    left: 50%;
    position: absolute;
    top: 0;
}

.eddie .margin.modal .form-group input {
    text-align: center;
    width: 50px;
    margin: 0 auto 5px;
}

.eddie .margin.modal .form-group.margin-top,
.eddie .margin.modal .form-group.margin-bottom {
    margin: -40px auto 0 -60px;
    white-space: nowrap;
}

.eddie .margin.modal .form-group.margin-bottom {
    top: 220px;
}

.eddie .margin.modal .form-group.margin-left {
    margin-left: -160px;
    top: 60px;
}

.eddie .margin.modal .form-group.margin-right {
    margin-left: 40px;
    top: 60px;
}

/* Modal list */

.eddie .modal .list {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: auto;
}

.eddie .modal .list li {
    border: 1px solid #DDD;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    float: left;
    width: 18%;
    margin: 1%;
    background-repeat: no-repeat;
    background-position: center 10px;
}

.eddie .modal .list.regions-list {
    width: 800px;
    margin: 0 auto;
}

.eddie .modal .list.regions-list li {
    width: 98%;
    text-align: left;
}

.eddie .modal .list li:hover {
    border-color: #0088cc;
}

.eddie .modal .list li h4 {
    margin: 70px 0 0;
    font-weight: 700;
    font-size: 14px;
    min-height: 48px;
}

.eddie .modal .list li:hover h4 {
    color: #428bca;
}

.eddie .modal .list li small {
    display: block;
    margin-bottom: 4px;
}

.eddie .modal .list.templates li {
    padding: 0;
    overflow: hidden;
}

.eddie .modal .list.templates li h4 {
    margin: 10px auto;
    display: none;
}

.eddie .modal .list.templates li .preview {
    background-size: cover;
    background-repeat: no-repeat;
    height: 340px;
}

/* Image modal */

.eddie .image .modal-body {
    padding: 0;
}

.eddie .image .menu {
    padding-right: 0;
}

.eddie .image .nav-pills {
    margin-top: 20px;
}

.eddie .image .nav-pills > li > a {
    border-radius: 0;
    border-left: 5px solid transparent;
}

.eddie .image .nav-pills > li.active > a {
    background: none;
    color: #0088cc;
    border-left: 5px solid #0088cc;
}

.eddie .image .nav-pills > li.active > a:hover {
    background: #EEE;
}

.eddie .image .tab-pane-body {
    height: 400px;
    max-height: 400px;
    border-left: 1px solid #EEE;
    padding: 20px;
    overflow-y: auto;
}

.eddie .image .tab-pane-actions {
    padding: 20px;
    border-top: 1px solid #EEE;
    border-left: 1px solid #EEE;
    overflow: auto;
}

.eddie .image .upload-loader {
    background: url(../img/loading-small.gif) no-repeat center;
    color: #aaa;
    height: 230px;
    line-height: 300px;
    text-align: center;
    width: 100%;
    display: none;
}

.eddie .image .importer .error {
    display: none;
}

.eddie .image .album .explorer.searching li {
    display: none;
}

.eddie .image .album .explorer.searching li.match {
    display: inline-block;
}

.eddie .image .album .finder {
    float: right;
    margin-top: -48px;
    margin-right: 5px;
}

.eddie .image .album .finder i {
    cursor: pointer;
}

.eddie .image .album .finder input {
    border: none;
    background: none;
    width: 1px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.eddie .image .album .finder input.focus {
    width: 100px;
}

.eddie .wait {
    background: url(../img/loading-small.gif) no-repeat center;
    color: #aaa;
    height: 230px;
    line-height: 300px;
    text-align: center;
    width: 100%;
}

.eddie .wait img {
    height: 18px;
    vertical-align: top;
    margin-right: 5px;
}

.eddie .image .album .breadcrumb li:last-child a {
    color: #777;
    cursor: inherit;
}

.eddie .image .album legend {
    text-transform: uppercase;
    color: #AAA;
    padding-bottom: 5px;
    font-size: 12px;
    display: none;
}

.eddie .image .album ul.list {
    color: #AAA;
}

.eddie .image .album ul.list li {
    padding: 0;
    width: 140px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-color: transparent;
    height: 140px;
}

.eddie .image .album ul.list li.folder a {
    background: url(../img/folder.png) no-repeat center top;
    height: 100px;
    padding-top: 35px;
    display: block;
}

.eddie .image .album ul.list li.image {
    vertical-align: top;
}

.eddie .image .album ul.list li.image:hover {
    color: #0088cc;
}

.eddie .product .explorer {
    max-height: 470px;
    overflow: auto;
    margin-top: 20px;
}

.eddie .product ul.list li.image {
    height: 190px;
}

.eddie .product form a {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.eddie .product [data-product=load-more] {
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;
}


.eddie .product ul.list li.image img,
.eddie .image .album ul.list li.image img {
    max-width: 100px;
    max-height: 100px;
    vertical-align: top;
    padding: 1px;
    cursor: pointer;
    margin-bottom: 5px;
}

.eddie .image .resizer {
    display: inline-block;
    padding-top: 7px;
}

.eddie .image .resizer label {
    font-weight: normal;
}

/* Color palette */

.eddie .color .palette {
    margin-top: 5px;
}

.eddie .color .palette div {
    width: 100%;
}

.eddie .color .palette span {
    display: inline-block;
    border: 1px solid #FFF;
    cursor: pointer;
    width: 23px;
    height: 23px;
    line-height: 1;
    overflow: hidden;
    text-align: center;
    text-shadow: 1px 1px 0 #fff;
    vertical-align: top;
}

.eddie .color .palette span.transparent:after {
    content: '\00D7';
    line-height: 1;
    font-size: 21px;
    color: red;
}

.eddie .color .palette span.selected-color:after {
    content: '\2714';
    line-height: 1;
    font-size: 21px;
}

.eddie .color .form-group,
.eddie .color .checkbox {
    margin-bottom: 0;
    margin-top: 15px;
}

.eddie .color .color-preview {
    border: 1px solid #ddd;
    border-radius: 3px;
    display: inline-block;
    float: left;
    height: 34px;
    margin-right: 10px;
    width: 34px;
    background-image: url(../img/transparent.png);
}

.eddie .color .color-input {
    width: auto;
}

/* File drop zone */

.eddie .dropzone {
    border: 4px dashed #ddd;
    border-radius: 4px;
    height: 100%;
    text-align: center;
}

.eddie .dropzone.over {
    border-color: #0088cc;
}

.eddie .dropzone h3,
.eddie .dropzone p,
.eddie .dropzone .btn {
    margin: 10px auto;
    width: 60%;
}

/* Hidden uploader */

.eddie .uploader {
    display: none;
}

/* Link toolbar */

.link-toolbar,
.image-toolbar {
    position: absolute;
    font-size: 14px;
    background: rgba(0,0,0,0.85);
    padding: 10px 20px;
    color: #AAA;
    font-weight: normal;
    text-align: left;
    font-family: Helvetica, Arial;
    font-style: normal;
    max-width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.link-toolbar hr,
.image-toolbar hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.link-toolbar span,
.image-toolbar span {
    color: #FFFFFF;
    cursor: pointer;
}

.link-toolbar span:hover,
.image-toolbar span:hover {
    color: #FFFFFF;
}

/* Tutorial */

.eddie .tutorial-dialog {
    display: none;
}

.eddie .tutorial-dialog .tutorial-header {
    padding: 10px 15px;
    border-bottom: 1px solid #DDD;
}

.eddie .tutorial-dialog .tutorial-header .modal-title{
    font-weight: bold;
}

.eddie .tutorial-dialog .tutorial-progressbar {
    height: 2px;
}
.eddie .tutorial-dialog .tutorial-progressbar-fill {
    height: 100%;
    background-color: #41aee7;
}

.eddie .tutorial-dialog [data-step="2"] .tutorial-progressbar-fill {width: 12%;}
.eddie .tutorial-dialog [data-step="3"] .tutorial-progressbar-fill {width: 24%;}
.eddie .tutorial-dialog [data-step="4"] .tutorial-progressbar-fill {width: 36%;}
.eddie .tutorial-dialog [data-step="5"] .tutorial-progressbar-fill {width: 48%;}
.eddie .tutorial-dialog [data-step="6"] .tutorial-progressbar-fill {width: 60%;}
.eddie .tutorial-dialog [data-step="7"] .tutorial-progressbar-fill {width: 72%;}
.eddie .tutorial-dialog [data-step="8"] .tutorial-progressbar-fill {width: 84%;}
.eddie .tutorial-dialog [data-step="9"] .tutorial-progressbar-fill {width: 100%;}


.eddie .tutorial-dialog .tutorial-title {
    line-height: 1.42857;
    margin: 0 0 10px;
}

.eddie .tutorial-dialog .tutorial-body {
    padding: 15px;
    font-size: 15px;
}

.eddie .tutorial-dialog .tutorial-footer {
    border-top: 1px solid #DDD;
    padding: 15px;
    margin-top: -10px;
}

.eddie .tutorial-dialog .tutorial-choice {
    padding: 10px;
    border-top: 1px solid #ddd;
}

.eddie.tutorial .tutorial-dialog {
    position: absolute;
    background: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 350px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 100px;
    margin-left: -175px;
    left: 50%;
    text-align: center;
    z-index: 3040;
    display: block;
}

.eddie .arrow,
.eddie.tutorial .eddie-page,
.eddie.tutorial .add-region-button,
.eddie.tutorial .restart-button,
.eddie.tutorial .settings-menu,
.eddie.tutorial .history-buttons,
.eddie.tutorial .save-menu,
.eddie.tutorial .help-button,
.eddie.tutorial .region-toolbar,
.eddie.tutorial .main-toolbar {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}

.eddie.tutorial .wrapper {
    overflow-y: hidden;
}

.eddie.tutorial.step1 .tutorial-dialog {
    top: 100px;
}

.eddie.tutorial.step2 .tutorial-dialog {
    top: 250px;
    margin-left: 60px;
}

.eddie.tutorial.step2 .eddie-page {
    opacity: 1;
}

.eddie.tutorial.step3 .tutorial-dialog {
    margin-left: -360px;
    top: 370px;
}

.eddie.tutorial.step3 .region-toolbar {
    opacity: 1;
}

.eddie.tutorial.step4 .tutorial-dialog {
    margin-left: 0px;
    top: 75px;
}

.eddie.tutorial.step4 .main-toolbar {
    opacity: 1;
}

.eddie.tutorial.step4 .wrapper {
    overflow-y: scroll;
}

.eddie.tutorial.step5 .tutorial-dialog {
    top: 75px;
    left: 50px;
}

.eddie.tutorial.step5 .add-region-button {
    opacity: 1;
}

/* .eddie.tutorial.step6 .tutorial-dialog {
    left: 120px;
} */

.eddie.tutorial.step6 .settings-menu {
    opacity: 1;
}

.eddie.tutorial.step6 .tutorial-dialog {
    left: 120px;
    margin-left: 65px;
}

.eddie.tutorial.step6 .restart-button {
    opacity: 1;
}



.eddie.tutorial.step7 .tutorial-dialog {
    margin-left: 160px;
}

.eddie.tutorial.step7 .history-buttons {
    opacity: 1;
}

.eddie.tutorial.step7 .tutorial-dialog {
    left: 100%;
    margin-left: -410px;
}

.eddie.tutorial.step7 .save-menu {
    opacity: 1;
}

.eddie.tutorial.step8 .tutorial-dialog {
    margin-left: -500px;
}

.eddie.tutorial.step8 .help-button {
    opacity: 1;
}

.eddie.tutorial.step9 .tutorial-dialog {
    top: 100px;
    margin-left: -175px;
    left: 50%;
}

.eddie.tutorial.step7 .arrow {
    background-position: -54px bottom;
    left: 310px;
}

.eddie.tutorial.step9 .arrow {
    display: none;
}

.eddie .arrow {
    background-image: url(../img/blue_arrow.png);
    background-repeat: no-repeat;
    background-position: 0 bottom;
    height: 54px;
    width: 50px;
    position: absolute;
    top: -30px;
    left: -30px;
}

.eddie.tutorial.step2 .arrow {
    opacity: 1;
}

/* Icons */

.fa-rotate-45 {
    transform: rotate(45deg);
}

.fa-rotate-135 {
    transform: rotate(135deg);
}

/* Extra */

.eddie .designs .modal-dialog .modal-content .modal-body ul.grid {
    margin: 0;
    padding: 0;
}

.eddie .designs .thumbnail .preview {
    cursor: pointer;
}

.eddie .designs .btn-up {
    margin-bottom: 20px;
    border: none;
}

.eddie .designs .search-design {
    margin-bottom: 20px;
}

.eddie .designs .search-design input {
    margin-bottom: 10px;
}

.eddie .template-title {
    font-size: 23px;
    margin: 5px;
    font-weight: bold;
    color: #aaa;
}

.eddie [data-if] {
    outline: 1px dotted orange;
}

.eddie .input-monospace {
    font-family: monospace;
    font-size: 14px;
}

@media (min-width: 992px) {
    .eddie .modal-lg {
        width: 1024px;
    }
    .eddie .regions .modal-lg {
        width: 1200px;
    }
}

.eddie .regions .modal-dialog .nav-pills {
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 10px;
}
.eddie .regions .modal-dialog .modal-body-padded{
    text-align: center;
}
.eddie .modals .product .modal-body-padded {
    padding-bottom: 40px;
}

.eddie div.lowres-option{
    margin-top: 7px;
}

.eddie div.lowres-option input{
    margin-right: 7px;
    margin-top: 3px;
}