
// Hints
.hint-box {
    font-size: 0.875rem;
    margin-bottom: 3rem;
    border-left: 4px solid $gray4;
    border-radius: 4px;
    background-color: $gray6;
    line-height: 1.5;
    color: $gray2;
    h1, h2, h3, h4, h5 {
        font-size: 0.875rem;
        margin-top: 0.2rem;
        margin-bottom: 0;
        line-height: 1.3;

        small{
            font-size: 0.875rem;
            margin-left: 1rem;
            color: $gray2;
        }
    }
    p { margin: 0.25rem 0 0 0;}
    ul {
        padding-inline-start: 1rem;
        margin-bottom: 0;
        list-style: disc;
    }

    background-repeat: no-repeat;
    background-position: 1.5rem 1.6rem;
    padding: 1.5rem 4rem;

    &.hint-info{
        background-image: url(../img/icons/hint-box-info.svg);
        border-left-color: $lightBlue;
        h1, h2, h3, h4, h5 {
            color: $blue;
        }
    }

    &.hint-good{
        background-image: url(../img/icons/hint-box-good.svg);
        border-left-color: $lightGreen;
        h1, h2, h3, h4, h5 {
            color: $green;
        }
    }

    &.hint-warning{
        background-image: url(../img/icons/hint-box-warning.svg);
        border-left-color: $orange;
        h1, h2, h3, h4, h5 {
            color: $orange;
        }
    }

    &.hint-alert{
        background-image: url(../img/icons/hint-box-alert.svg);
        border-left-color: $red;
        h1, h2, h3, h4, h5 {
            color: $red;
        }
    }
}

.tooltip-inner {
    font-size: 13px;
    padding: 0.5rem 1rem;
}