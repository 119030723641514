// Plan Features

.section.integrations {

    .integration .beta {
        position: absolute;
        left: -8px;
        top: 18px;
        background-color: #0E84B6;
        -webkit-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
        -moz-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
        -o-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
        -ms-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
        transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
        text-align: center;
        color: white;
        padding: 0 .5em;
        border-top: 0px;
        line-height: 20px;
        margin: 0;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        z-index: 1;

        &:after {
            content: ' ';
            position: absolute;
            right: -19px;
            top: 0px;
            border-right: 20px solid transparent;
            border-bottom: 20px solid #0E84B6;
        }

        &:before {
            content: ' ';
            position: absolute;
            left: -19px;
            top: 0px;
            border-left: 20px solid transparent;
            border-bottom: 20px solid #0E84B6;
        }
    }
}