/* List */

.list ul.table {
    margin: 0;
    display: table;
    width: 100%;
    padding: 0;
    background-color: #fff;
}

.list ul.table > div {
    display: table-row;
}

.list ul.table > div > div {
    display: table-cell;
    vertical-align: top;
    padding: 10px 21px 5px;
    border-bottom: 1px solid #EEE;
}

.list ul.table > div:last-child > div {
    border-bottom: none;
}

.list ul.table > div.selected > div {
    background-color: #F3FBFE;
}

.list ul.table > div > div.check-cell {
    text-align: center;
    width: 50px;
}

.list ul.table > div > div.check-cell > input {
    display: none;
}

.list ul.table > div > div.check-cell > .iconic {
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
    margin: 0;
    display: inline-block;
    cursor: pointer;
}

.list ul.table > div:hover > div.check-cell:not(.readonly) > .iconic {
    background-image: url(../img/icons/lists/item-selector-unchecked.svg) !important;
}

.list ul.table > div.selected > div.check-cell:not(.readonly) > .iconic {
    background-image: url(../img/icons/lists/item-selector-checked.svg) !important;
}

.list ul.table > div > div.main-cell {
    padding-left: 0;
    padding-top: 11px;
}

.list ul.table > div > div.main-cell > .name > a,
.list ul.table > div > div.main-cell > .name > .title {
    display: inline-block;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1;

}
.list ul.table > div > div.main-cell > .name > .title {
  color: #696969
}

.list ul.table > div > div.main-cell > .name > p {
    color: #696969;
    font-size: 14px;
    margin: 1irpx 0 0 0;
}

.list ul.table > div > div.main-cell > .name .grayed {
    color: #ccc;
}

.list ul.table > div > div.info-cell {
    text-align: right;
    width: 100px;
    padding: 19px 21px 5px;
}

.list ul.table > div > div.info-cell:last-of-type{
    padding-right: 60px;
}

.list ul.table > div > div.info-cell > strong {
    font-weight: 400;
    font-size: 1rem;
    color: #696969;
    display: block;
    line-height: 1;
    /* letter-spacing: 1px; */
    white-space: nowrap;
}

.list ul.table > div > div.info-cell > small {
    font-size: 0.875rem;
    color: #b7b7b7;
    display: block;
    white-space: nowrap;
}

.list ul.table > div > div.custom-cell {
    text-align: right;
}

.list ul.table > div > div.custom-cell:last-of-type {
    padding-right: 60px;
}

.list ul.table > div > div.custom-cell > strong {
    font-weight: 400;
    font-size: 0.875rem;
    color: #696969;
    display: block;
    line-height: 1;
    /* letter-spacing: 1px; */
    margin-top: 10px;
}

.list ul.table > div > div.custom-cell > small {
    font-size: 0.875rem;
    color: #b7b7b7;
    display: block;
}

.list .main-cell .thumbnail {
    background-size: cover;
    background-repeat: no-repeat;
    float: left;
    height: 50px;
    width: 50px;
    margin-bottom: 0;
}

.load-more {
    margin: 30px auto;
    width: 200px;
    display: block;
}

.list .loading-box {
    margin: 40px auto;
}